const initialstate = {
    outletInfo: null
};

const OutletReducer = (state = initialstate, action) => {
    switch (action.type) {
      case "UPDATE_OUTLET_DETAILS":
        return {
          ...state,
          outletInfo: action.payload
        };
      default:
        return state;
    }
  };
  
export default OutletReducer;