import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <section className="vh-100  my_login">
            <div className="d-flex align-items-center justify-content-center vh-100">
                    <div className="text-center  p-3">
                        <div className="pb-1">
                            {/* <a href="index.php"> */}
                                <img src="/images/logo_f_box.png" width="250" alt="" />
                            {/* </a> */}
                        </div>
                        <h1 className="display-1 fw-bold">404</h1>
                        <p className="fs-3"> <span className="text-danger">{ t('opps') }</span> { t('page_not_found') }</p>
                        <p className="lead">
                            { t('the_page_youre_looking_for_doesnt_exist') }
                        </p>
                        <Link onClick={(e) => {e.preventDefault();navigate(-1)}} className="btn blue_rev_btn ">{ t('go_back') }</Link>
                    </div>
                </div>
        
        </section>
    );
}