import store from 'store';
import APIService from "services/api.service";
import api from "config/api";
import { successMessage, errorMessage } from 'utils/ToastNotificationHelper';
import { getUserInfo, storeUserInfo, removeLocalStorage } from 'utils/StorageHelper';
import { useTranslation } from 'react-i18next';
import { dateDifferenceMinutes } from 'utils/DateHelper';
import { LOGGEDIN_USER_INFO_REFRESH_EVERY_MINUTES } from 'config/config';

export const loginUser = async (data,callback) => {
    const response = await APIService.submitData(api.login.url, data);
    if(response){
        callback(response.status,response);
    }
};

export const refreshToken = async (callback) => {
    const response = await APIService.submitData(api.refreshToken.url,{});
    if(response){
      if(response.status){
        var obj = getUserInfo();
        obj.authorization = response.data?.authToken;
        storeUserInfo(obj);
        callback();
      }else{
        console.log("error");
      }
    }
  };


  export const logoutUser = async (callback) => {
    const response = await APIService.getData(api.logout.url);
    if(response){
      if(response.status){
        removeUserSessionData();
        callback()
      }
      else{
        errorMessage(response.message)
      }
    }
  };

export const removeUserSessionData = () => {
  removeLocalStorage("userInfo");
  removeLocalStorage("activeRole");
  removeLocalStorage("vendorInfo");
  removeLocalStorage("outletInfo");
  store.dispatch({ 
    type: 'UPDATE_USER_DETAILS',
    payload: {} 
  })
  store.dispatch({ 
    type: 'UPDATE_ACTIVE_ROLE_DETAILS',
    payload: {} 
  })
  store.dispatch({ 
    type: 'UPDATE_OUTLET_DETAILS',
    payload: {} 
  })
  store.dispatch({ 
    type: 'UPDATE_VENDOR_DETAILS',
    payload: {} 
  })
}
export const forgotPassword = async (data,callback) => {
  const response = await APIService.submitData(api.forgotPassword.url, data);
  if(response){
    callback(response.status,response);
  }
};

export const resetPassword = async (data,callback) => {
  const response = await APIService.submitData(api.resetPassword.url, data);
  if(response){
    callback(response.status,response);
  }
};

export const updateProfile = async (data,callback) => {
  const response = await APIService.submitData(api.updateProfile.url, data);
  if(response){
	  callback(response.status,response);
  }
};

export const manageLoggedinUserInfo = () => {
  // update settings every LOGGEDIN_USER_INFO_REFRESH_EVERY_MINUTES 
  const userInfo = getUserInfo();
  var allowApiCall = true;
  if(userInfo){
    var minutes = dateDifferenceMinutes(new Date(), userInfo.last_updated_at);
    if(userInfo.last_updated_at && minutes <= LOGGEDIN_USER_INFO_REFRESH_EVERY_MINUTES){
      // only update state
      updateUserInfoState();
      allowApiCall = false;
    } 
  }
  if(allowApiCall){
    // need to fetch latest info
    myProfile();
  }
} 
export const updateUserInfoState = () => {
  const userInfo = getUserInfo();
  store.dispatch({
    type: "UPDATE_USER_DETAILS",
    payload: userInfo,
  });
} 
export const myProfile = async () => {
  const response = await APIService.getData(api.myProfile.url);
  if(response){
    if(response.status){
      const userData = response.data;
      const localData = getUserInfo();
      const mergeData = {...localData, ...userData};
      storeUserInfo(mergeData);
      updateUserInfoState();
      // store.dispatch({ 
      //   type: 'UPDATE_USER_DETAILS',
      //   payload: mergeData 
      // })
    }
    else{
      errorMessage(response.message)
    }
  }
};
export const activateUserVendorAccount = async (callback) => {
  const response = await APIService.getData(api.applyActivation.url);
  if(response){
	  callback(response.status,response);
  }
};

export const activationHistoryList = async (data, callback) => {
  const response = await APIService.submitData(api.activationHistoryList.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const productionCCAvenueUrl =  async (data, callback) => {
  const response = await APIService.submitData(api.productionCCAvenueUrl.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const startDelegateAccess = async (data,callback) => {
  const response = await APIService.submitData(api.startDelegateAccess.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const stopDelegateAccess = async (data,callback) => {
  const response = await APIService.submitData(api.stopDelegateAccess.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const adminDashboardInfo = async (callback) => {
  const response = await APIService.getData(api.adminDashboard.url);
  if(response){
    callback(response.status,response)
  }
};
export const verify2FACode = async (data,callback) => {
  const response = await APIService.submitData(api.verify2FACode.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const resend2FACode = async (data,callback) => {
  const response = await APIService.submitData(api.resend2FACode.url, data);
  if(response){
	  callback(response.status,response);
  }
};
export const getCurrentLocation = () => {
  if (navigator.geolocation) {
    // console.log("location permission");
    navigator.geolocation.getCurrentPosition(successLocationFunction, errorLocationFunction);
  } else {
    // console.log("location not supported");
    // alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
  }
}
function successLocationFunction(position) {
  // console.log("location getting.........");
    var lat = position.coords.latitude;
    var long = position.coords.longitude;
    store.dispatch({ 
      type: 'UPDATE_USER_LOCATION',
      payload: {
        lat: lat,
        lng: long
      } 
    })
    // console.log('Your latitude is :'+lat+' and longitude is '+long);
}
function errorLocationFunction(error) {
    // console.log("error for location......");
    // console.log(error);
    // alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
}