import moment from "moment";
import momentTimezone from 'moment-timezone';
import store from "store";
import { settingsSelector } from "components/_selectors/authSelectors";
import { userTimeZoneSelector } from "components/_selectors/authSelectors";

export const getCurrentDateTime = () => {
  return moment();
};
export const getServerFormattedDate = (date) => {
  var dateFormat = "YYYY-MM-DD";
  // if(settingsSelector(store.getState()).date_format_customer_web){
  //   dateFormat = settingsSelector(store.getState()).date_format_customer_web;
  // }
  dateFormat = dateFormat.split(" ")[0];
  return moment(date).format(dateFormat);
};
export const getFormattedDateTime = (date) => {
  var dateFormat = "DD/MM/YYYY h:mm A";
  // if(settingsSelector(store.getState()).date_format_customer_web){
  //   dateFormat = settingsSelector(store.getState()).date_format_customer_web;
  // }
  // this formats date based on user timezone
  var userTimezone = userTimeZoneSelector(store.getState());
  return (momentTimezone(date).tz(userTimezone).format(dateFormat));
  // return moment(date).format(dateFormat);
};
export const getFormattedDate = (date) => {
  var dateFormat = "DD/MM/YYYY";
  // if(settingsSelector(store.getState()).date_format_customer_web){
  //   dateFormat = settingsSelector(store.getState()).date_format_customer_web;
  // }
  dateFormat = dateFormat.split(" ")[0];
  // this formats date based on user timezone
  var userTimezone = userTimeZoneSelector(store.getState());
  return (momentTimezone(date).tz(userTimezone).format(dateFormat));
  // return moment(date).format(dateFormat);
};
export const getFormattedTime = (time) => {
  var dateFormat = "h:mm A";
  var date = moment().format("YYYY-MM-DD") + " " + time;
  // this formats date based on user timezone
  var userTimezone = userTimeZoneSelector(store.getState());
  return (momentTimezone(date).tz(userTimezone).format(dateFormat));
  // return moment(date).format(dateFormat);
};
export const getServerFormattedTime = (time) => {
  var dateFormat = "H:mm:ss";
  var date = moment().format("YYYY-MM-DD") + " " + time;
  return moment.utc(date).format(dateFormat);
  // return moment(date).format(dateFormat);
};
export const getDisplayFormattedTime = (time) => {
  var dateFormat = "HH:mm";
  var date = moment().format("YYYY-MM-DD") + " " + time;
  var userTimezone = userTimeZoneSelector(store.getState());
  return (momentTimezone(date).tz(userTimezone).format(dateFormat));
  // return moment(date).format(dateFormat);
};
export const getMaxAllowedBirthDate = () => {
  return moment().subtract(10, "years")._d;
};
export const dateDifferenceMinutes = (date1, date2=moment()) => {
  // date1 should be larger than date2
  date1 = moment(date1);
  date2 = moment(date2);
  var duration = moment.duration(date1.diff(date2));
  var minutes = duration.asMinutes();
  return Math.round(minutes);
}
export const getTimeSlots = () => {
  // Generate time options from 12:00 am to 11:30 pm in 30-minute intervals
  const timeOptions = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 30) {
      const time = `${String(h % 12 || 12).padStart(2, '0')}:${String(m).padStart(2, '0')} ${h >= 12 ? 'PM' : 'AM'}`;
      timeOptions.push({
        value: convertTime12to24Hours(time),
        name: time
      });
    }
  }
  return timeOptions;
}
export const convertTime12to24Hours = (inputTime)=>{
  // Parse the input time using Moment.js
const parsedTime = moment(inputTime, 'h:mm A');

// Format the parsed time in 24-hour format
const outputTime = parsedTime.format('HH:mm');
return outputTime;
}

export const convertTime24to12Hours = (inputTime)=>{
  // Parse the input time using Moment.js
const parsedTime = moment(inputTime, 'h:mm');

// Format the parsed time in 24-hour format
const outputTime = parsedTime.format('HH:mm A');
return outputTime;
}