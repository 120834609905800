const initialstate = {
    vendorInfo: null,
    activeRole: null,
};

const VendorReducer = (state = initialstate, action) => {
    switch (action.type) {
      case "UPDATE_VENDOR_DETAILS":
        return {
          ...state,
          vendorInfo: action.payload
        };
      case "UPDATE_ACTIVE_ROLE_DETAILS":
        return {
          ...state,
          activeRole: action.payload
        };
      default:
        return state;
    }
  };
  
export default VendorReducer;