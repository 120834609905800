import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function FallbackUI() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <section className="vh-100  my_login">
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center  p-3">
          <div className="pb-1">
            {/* <a href="index.php"> */}
            <img src="/images/logo_f_box.png" width="250" alt="" />
            {/* </a> */}
          </div>
          <h1 className="display-1 fw-bold">500</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">{t("opps")}</span>{" "}
            {t("something_went_wrong")}
          </p>
          <p className="lead">{t("the_page_youre_looking_for_doesnt_exist")}</p>
          <button
            type="button"
            onClick={() => {
              document.location.reload(true);
            }}
            className="btn blue_rev_btn "
          >
            {t("reload")}
          </button>
        </div>
      </div>
    </section>
  );
}
