import store from "store";
import { getActiveRole, getStaffPermissionList, hasOutletRoleAccess, hasOutletStaffRoleAccess, hasSuperAdminRoleAccess, hasVendorRoleAccess, hasVendorStaffRoleAccess } from "./StorageHelper";
import { VENDOR_ROLE_SLUG, OUTLET_ROLE_SLUG, SUPER_ADMIN_ROLE_SLUG } from "config/config";
import { userPermissionListSelector } from "components/_selectors/authSelectors";

export const hasUserAccessFunctionality = (
    functionalityName
  ) => {
    var userHasVendorRole = hasVendorRoleAccess();
    var userHasOutletRole = hasOutletRoleAccess();
    var userHasSuperAdminRole = hasSuperAdminRoleAccess();
    var userHasVendorStaffRole = hasVendorStaffRoleAccess();
    var userHasOutletStaffRole = hasOutletStaffRoleAccess();
    if(userHasVendorStaffRole || userHasOutletStaffRole){
      // return true;
      let hasPermission = checkVendorStaffPermission(functionalityName)
      return hasPermission;
    }
    else{
      switch (functionalityName) {
        case "offer_module":
        return userHasVendorRole || userHasOutletRole || userHasSuperAdminRole;
        case "offer_create":
        case "offer_edit":
        return userHasVendorRole || userHasOutletRole;
        case "offer_delete":
        case "outlet.edit":
        case "outlet_status":  
        return userHasVendorRole; // allowed for vendor and vendor staff
        case "outlet_module":
        case "invoice_module":
        case "transactions_module":
        return userHasVendorRole || userHasSuperAdminRole;
        case "outlet_list":
        case "outlet_list_filter":
        case "outlet.create":
        case "subscription_module":
        case "rating_module":
        case "vendor_wise_settings": 
        case "vendor_wise_general_settings":  
        case "vendor_wise_brand_settings": 
        case "vendor_wise_loyalty_coin_settings": 
        case "vendor_wise_reward_level_settings": 
        case "vendor_wise_reward_level_settings_add": 
        case "vendor_wise_reward_level_settings_edit": 
        case "vendor_wise_reward_level_settings_delete": 
        case "vendor_wise_mobile_application_settings": 
        case "vendor_wise_reward_settings": 
        case "vendor_wise_developer_settings": 
        case "vendor_wise_developer_settings_create":
        case "vendor_wise_developer_settings_edit":
        case "vendor_wise_developer_settings_resend":
        case "vendor_wise_developer_settings_delete":
        case "photo_gallery_approve": 
        case "offer_outlet_selection_remove":
        case "offer_outlet_selection_list":
        case "product_outlet_selection_remove":
        case "product_outlet_selection_list":
        case "outlet_dropdown_list":
        case "product_category_add":
        case "product_category_edit":
        case "product_category_delete":
        case "vendor_status":
        case "product_category_status":
        case "product_delete":  
        return userHasVendorRole; // allowed for vendor and vendor staff
        case "vendor_list_add_edit_product":
        case "vendor_filter_list_product":
        case "outlet_settings":
        case "product_stock_status": 
        return userHasOutletRole;
        case "rp_transaction_module":
        case "ticket_list": 
        case "ticket_reply": 
        return userHasSuperAdminRole || userHasOutletRole || userHasVendorRole;
        case "photo_gallery_settings":
        case "photo_gallery_create":
        case "photo_gallery_edit":
        case "photo_gallery_delete":
        case "product_category_module":
        case "product_module":
        case "product_edit":
        case "product_status":
        case "offer_status":
        case "staffing_module":
        case "create_ticket":
        case "customer.create":
        case "customer.detail":  
        return userHasOutletRole || userHasVendorRole;
        case "customer_module":
        return userHasSuperAdminRole || userHasOutletRole || userHasVendorRole;
        case "vendor_industry_add_edit":
        case "vendor_reward_type_edit":
        case "vendor_content_add_edit":
        case "state_add_edit":
        case "taxclass_add_edit":
        case "membership_plan_add_edit":
        case "language_add_edit":
        case "vendor_add_edit":
        case "vendor_module":
        case "admin_reports":
        case "admin_settings":
        case "reward_type":
        case "offer_vendor_info_column":
        case "vendor_rating_status_column":
        case "vendor_list_filter":
        case "country_list_filter":
        return userHasSuperAdminRole;
        default:
        return false;
      }
    }
  }
  export const checkVendorStaffPermission = (functionalityName) => {
    var permissionList = getStaffPermissionList();
    var userHasVendorStaffRole = hasVendorStaffRoleAccess();
    var userHasOutletStaffRole = hasOutletStaffRoleAccess();
    switch (functionalityName) {
      // outlet
      case "outlet_module":
      return permissionList.includes("outlet.list") || permissionList.includes("outlet");
      case "outlet.create":
      return permissionList.includes("outlet.create");  
      case "outlet.edit":
      return permissionList.includes("outlet.edit");  
      // offer
      case "offer_module":
        return permissionList.includes("offer.list");
      case "offer_create": 
      return permissionList.includes("offer.create");
      case "offer_edit":
      case "offer_status":
        return permissionList.includes("offer.edit");
      case "offer_outlet_selection_remove":
      case "offer_outlet_selection_list":
          return permissionList.includes("offer.create") || permissionList.includes("offer.edit");
      case "offer_delete":
        return permissionList.includes("offer.delete");
      // invoice
      case "invoice_module":
        return permissionList.includes("invoice")
      // transaction
      case "transactions_module":
        return permissionList.includes("payment.transaction")
      // outlet
      case "outlet_list":
      return permissionList.includes("outlet.list")
      case "outlet_list_filter":
      case "outlet_dropdown_list":
      return userHasVendorStaffRole;
      // return permissionList.includes("outlet.list") || permissionList.includes("outlet")
      // subscription history
      case "subscription_module":
        return permissionList.includes("subscription.history")
      // vendor wise settings
      case "vendor_wise_settings": 
      return permissionList.includes("setting");
      case "vendor_wise_general_settings":  
      return permissionList.includes("general.setting");
      case "vendor_wise_brand_settings": 
      return permissionList.includes("brand.setting");
      case "vendor_wise_loyalty_coin_settings": 
      return permissionList.includes("loyalty.coin.setting");
      // vendor reward level settings
      case "vendor_wise_reward_level_settings": 
      return permissionList.includes("reward.level.list");
      case "vendor_wise_reward_level_settings_add": 
      return permissionList.includes("reward.level.create");
      case "vendor_wise_reward_level_settings_edit": 
      return permissionList.includes("reward.level.edit");
      case "vendor_wise_reward_level_settings_delete": 
      return permissionList.includes("reward.level.delete");
      // vendor application settings
      case "vendor_wise_mobile_application_settings": 
      return permissionList.includes("mobile.application.setting");
      // vendor reward setting
      case "vendor_wise_reward_settings": 
      return permissionList.includes("reward.setting");
      // vendor developer setting
      case "vendor_wise_developer_settings":  
      return permissionList.includes("developer.setting.list");
      case "vendor_wise_developer_settings_create":  
      return permissionList.includes("developer.setting.create");
      case "vendor_wise_developer_settings_edit":  
      return permissionList.includes("developer.setting.edit");
      case "vendor_wise_developer_settings_delete":  
      return permissionList.includes("developer.setting.delete");
      case "vendor_wise_developer_settings_resend":  
      return permissionList.includes("developer.setting.resend");
      case "photo_gallery_settings":
      return permissionList.includes("photo.gallery.list");
      case "photo_gallery_create":
        return permissionList.includes("photo.gallery.create");
      case "photo_gallery_edit":
        return permissionList.includes("photo.gallery.edit");
      case "photo_gallery_delete":
        return permissionList.includes("photo.gallery.delete");
      // other
      case "rating_module":
      case "photo_gallery_approve":
      case "staffing_module":
      case "vendor_status":
      return false;
      case "vendor_list_add_edit_product":
      case "vendor_filter_list_product":
      case "outlet_settings":
      return false;
      case "rp_transaction_module":
      return permissionList.includes("rp.transactions");
      case "product_category_module":
        return permissionList.includes("category.list")
      case "product_category_add":
        return permissionList.includes("category.create")
      case "product_category_edit":
        return permissionList.includes("category.edit")
        case "product_category_delete":
          return permissionList.includes("category.delete")
      case "product_module":
        return permissionList.includes("product.list")
      case "product_edit": 
      case "product_status": 
        return permissionList.includes("product.edit")
      case "product_delete": 
        return permissionList.includes("product.delete")
        case "product_stock_status": 
        return permissionList.includes("product.edit") && userHasOutletStaffRole;
      case "product_outlet_selection_remove":
      case "product_outlet_selection_list":
        return userHasVendorStaffRole && (permissionList.includes("product.create") || permissionList.includes("product.edit"));
      case "customer_module":
        return permissionList.includes("customer.list");
      case "customer.create":
      case "customer.detail":
        return permissionList.includes("customer.create");
      case "ticket_list": 
      case "create_ticket":
      case "ticket_reply": 
        return true
      default:
      return false;
    }
  }
  export const hasEditProductOfferAccess = (availableOutlets, currentOutlet) => {
    if(currentOutlet && Object.keys(currentOutlet).length > 0){
      // for outelet and outlet staff: if offer/product is for only one outlet 
      return availableOutlets.length == 1 && availableOutlets.includes(currentOutlet.id)
    }
    else{
      // for vendor and vendor staff
      return true;
    }
  }