const endpoints = {
    // general API
    "settings" : { url: '/settings', method: 'get' },
    "timezone" : { url: '/timezone-list', method: 'post' },
    "industry" : { url: '/industry-list', method: 'post' },
    "state" : { url: '/state-list', method: 'post' },
    "city" : { url: '/city-list', method: 'post' },

    // account activation
    "applyActivation" : { url: '/apply-for-activation', method: 'get' },
    "activationHistoryList" : { url: '/activation-history', method: 'post' },
    
    // login module
    "login" : { url: '/login', method: 'post' },
    "resendOTP" : { url:'/resend-otp', method: 'post'},
    "forgotPassword" : { url: '/forgot-password', method: 'post' },
    "resetPassword" : { url: '/reset-forgot-password', method: 'post' },
    "refreshToken" : { url:'/refresh-token', method: 'post'},
    "logout" : { url: '/logout', method: 'get' },
    "verify2FACode" : { url:'/verify-two-factor-code', method: 'post'},
    "resend2FACode" : { url:'/resend-two-factor-code', method: 'post'},

    //my profile
    "updateProfile" : { url: '/update-profile', method: 'post' },
    "myProfile" : { url: '/my-profile', method: 'get' },

    //settings
    "getGeneralSetting" : { url: '/general-settings', method: 'get' },
    "updateGeneralSetting" : { url: '/general-settings', method: 'post' },
    "getBrandSetting" : { url: '/brand-settings', method: 'get' },
    "updateBrandSetting" : { url: '/brand-settings', method: 'post' },
    "getLoyaltyCoinSetting" : { url: '/loyalty-coin-settings', method: 'get' },
    "updateLoyaltyCoinSetting" : { url: '/loyalty-coin-settings', method: 'post' },
    "getRewardLevel" : { url: '/reward-level/list', method: 'post' },
    "createRewardLevel" : { url: '/reward-level/create', method: 'post' },
    "rewardLevelInfo" : { url: '/reward-level/info', method: 'post' },
    "editRewardLevel" : { url: '/reward-level/edit', method: 'post' },
    "deleteRewardLevel" : { url: '/reward-level/delete', method: 'post' },
    "getApplicationSetting" : { url: '/application-settings', method: 'get' },
    "updateApplicationSetting" : { url: '/application-settings', method: 'post' },
    "getLevelUpRewardSetting" : { url: '/reward-setting/levelup', method: 'get' },
    "updateLevelupRewardSetting" : { url: '/reward-setting/levelup', method: 'post' },
    "getFollowRewardSetting" : { url: '/reward-setting/follow', method: 'get' },
    "updateFollowRewardSetting" : { url: '/reward-setting/follow', method: 'post' },
    "getRatingRewardSetting" : { url: '/reward-setting/rating', method: 'get' },
    "updateRatingRewardSetting" : { url: '/reward-setting/rating', method: 'post' },
    "getProductRewardSetting" : { url: '/reward-setting/product', method: 'get' },
    "updateProductRewardSetting" : { url: '/reward-setting/product', method: 'post' },
    "getGeneralRewardSetting" : { url: '/reward-setting/general', method: 'get' },
    "updateGeneralRewardSetting" : { url: '/reward-setting/general', method: 'post' },
    "getPhotoGallery" : { url: '/photo-gallery', method: 'post' },
    "uploadPhotoGallery" : { url: '/create-photo-gallery', method: 'post' },
    "deletePhotoGallery" : { url: '/delete-photo-gallery', method: 'post' },
    "approvePhotoGallery" : { url: '/approve-photo-gallery', method: 'post' },
    "infoPhotoGallery" : { url: '/photo-gallery-info', method: 'post' },
    "updatePhotoDetail" : { url: '/edit-photo-gallery', method: 'post' },
    "smsReport" : { url: '/sms-report', method: 'post' },
    "otpReporter":{ url: '/otp-report', method: 'post' },
   
    
    //paymentGateway
    "paymentGateway":{ url: '/payment-gateway', method: 'post' },
    "paymentGatewayEdit":{ url: '/edit-payment-gateway', method: 'post' },
    "paymentGatewayInfo":{ url: '/payment-gateway-info', method: 'post' },

    //language
    "language":{ url: '/language', method: 'post' },
    "languageAdd":{ url: '/create-language', method: 'post' },
    "languageStatus":{ url: '/manage-language-status', method: 'post' },
    "languageInfo":{ url: '/language-info', method: 'post' },
    "languageEdit":{ url: '/edit-language', method: 'post' },

    //country
    "country" : { url: '/country-list', method: 'post' },
    "countryAdd" : { url: '/create-country', method: 'post' },
    "countryInfo" : { url: '/country-info', method: 'post' },
    "countrEdit" : { url: '/edit-country', method: 'post' },
    //tax class
    "taxClass":{ url: '/tax-class', method: 'post' },
    "taxClassAdd":{ url: '/create-tax-class', method: 'post' },
    "taxClassEdit":{ url: '/edit-tax-class', method: 'post' },
    "taxClassinfo":{ url: '/tax-class-info', method: 'post' },
    "taxClassdelete":{ url: '/delete-tax-class', method: 'post' },
    //state
    "stateAdd" : { url: '/create-state', method: 'post' },
    "stateInfo": { url: '/state-info', method: 'post' },
    "stateEdit": { url: '/edit-state', method: 'post' },
    //city
    "cityAdd": { url: '/create-city', method: 'post' },
    "cityInfo": { url: '/city-info', method: 'post' },
    "cityEdit": { url: '/edit-city', method: 'post' },
  
    //outlet
    "outlets" : { url: '/outlet', method: 'post' },
    "createOutlet" : { url: '/create-outlet', method: 'post' },
    "updateOutlet" : { url: '/edit-outlet', method: 'post' },
    "updateOutletStatus" : { url: '/manage-outlet-status', method: 'post' },
    "outletInfo" : { url: '/outlet-info', method: 'post' },
    //reward type
    "rewardType":{ url: '/reward-type', method: 'post' },
    "rewardTypeInfo":{ url: '/reward-type-info', method: 'post' },
    "rewardTypeEdit":{ url: '/edit-reward-type', method: 'post' },
    //content
    "contentPage":{ url: '/content-pages', method: 'post' },
    "contentPageAdd":{ url: '/create-content-pages', method: 'post' },
    "contentPageEdit":{ url: '/edit-content-pages', method: 'post' },
    "contentPageInfo":{ url: '/content-pages-info', method: 'post' },
    "contentPageDelete":{ url: '/delete-content-pages', method: 'post' },
    "updateContentPageStatus" : { url: '/manage-content-pages-status', method: 'post' },
    //membershipplan
    "membershipplan":{ url: '/membership-plan', method: 'post' },
    "membershipplanAdd":{ url: '/create-membership-plan', method: 'post' },
    "membershipplanEdit":{ url: '/edit-membership-plan', method: 'post' },
    "membershipplanInfo":{ url: '/membership-plan-info', method: 'post' },
    //role
    "role":{ url: '/role-list', method: 'post' },


    //customers
    "customers" : { url: '/customers', method: 'post' },
    "verifyCustomer" : { url: '/verify-customer', method: 'post' },
    "sendOTPtoCustomer" : { url: '/send-otp-customer', method: 'post' },
    "createCustomer" : { url: '/create-customer', method: 'post' },
    "customerTransaction" : { url: '/customer-transactions', method: 'post' },
    "getCustomerDetail" : { url: '/customer-info', method: 'post' },

    //Rewards
    "redeeReward" : { url: '/redeem-reward', method: 'post' },
    "generalReward" : { url: '/general-reward', method: 'post' },
    "generalRewardAmountCalculation" : { url: '/general-reward-amount-calculation', method: 'post' },
    "searchProductReward" : { url: '/search-product-reward', method: 'post' },
    "creditProductReward" : { url: '/product-reward', method: 'post' },
    "updateRewardSettingStatus" : { url: '/reward-setting/status', method: 'post' },

    //catalog
    "productCategory" : { url: '/categories', method: 'post' },
    "products" : { url: '/products', method: 'post' },
    "createProduct" : { url: '/create-product', method: 'post' },
    "updateProduct" : { url: '/edit-product', method: 'post' },
    "deleteProduct" : { url: '/delete-product', method: 'post' },
    "productInfo": { url: '/product-info', method: 'post' },
    "updateProductStatus" : { url: '/manage-product-status', method: 'post' },
    "updateProductStockStatus" : { url: '/manage-stock', method: 'post' },
    "createProductCategory" : { url: '/create-category', method: 'post' },
    "editProductCategory" : { url: '/edit-category', method: 'post' },
    "deleteProductCategory" : { url: '/delete-category', method: 'post' },
    "productCategoryInfo" : { url: '/category-info', method: 'post' },
    "updateProductCategoryStatus" : { url: '/manage-category-status', method: 'post' },

    //offers
    "offers" : { url: '/offers', method: 'post' },
    "createOffer" : { url: '/create-offer', method: 'post' },
    "updateOffer" : { url: '/edit-offer', method: 'post' },
    "deleteOffer" : { url: '/delete-offer', method: 'post' },
    "offerInfo" : { url: '/offer-info', method: 'post' },
    "updateOfferStatus" : { url: '/manage-offer-status', method: 'post' },
    
    //RP Transactions
    "rpTransactions" : { url: '/rp-transactions', method: 'post' },

    //vendor profile
    "vendorProfile" : { url: '/vendor-profile', method: 'get' },
    "vendorRatings" : { url: '/ratings', method: 'post' },
    "vendorRatingsStatus" : { url: '/manage-rating-status', method: 'post' },

    //outlet profile
    "outletProfile" : { url: '/outlet-profile', method: 'get' },
    "updateOutletProfile" : { url: '/update-my-outlet-profile', method: 'post' },

    //dashboard
    "adminDashboard" : { url: '/admin-dashboard', method: 'get' },
    "vendorDashboard" : { url: '/vendor-dashboard', method: 'get' },
    "outletDashboard" : { url: '/outlet-dashboard', method: 'get' },

    
    //industry Status
    "updatevendorIndustryStatus" : { url: '/manage-industry-status', method: 'post' },
    //industry Add
    "createvendorIndustry" : { url: '/create-industry', method: 'post' },
    //industry get-Update
    "vendorIndustryInfo" : { url: '/industry-info', method: 'post' },
    "editvendorIndustry" : { url: '/edit-industry', method: 'post' },


    //Vendor List
    "vendor" : { url: '/vendor', method: 'post' },
    "createVendor" : { url: '/create-vendor', method: 'post' },
    "vendorInfo" : { url: '/vendor-detail', method: 'post' },
    "updateVendor" : { url: '/edit-vendor', method: 'post' },

    //vendorveryfication
    "vendorVerification" : { url: '/pending-verification', method: 'post' },
    //transaction
    "transactions" : { url: '/my-transactions', method: 'post' },
    //Approval
    "rejectPandingVarification" : { url: '/pending-verification-reject', method: 'post' },
    "approvedPandingVarification" : { url: '/pending-verification-accept', method: 'post' },

    //invoices
    "invoices" : { url: '/my-invoices', method: 'post' },
    "downloadInvoice" : { url: '/invoice', method: 'get' },

    //subscriptions
    "subscriptions" : { url: '/my-subscriptions', method: 'post' },
    "vendorSubscriptionDetail" : { url: '/vendor-subscription-detail', method: 'post' },

    // developer settings
    "developerKeyList" : { url: '/developer-api-key', method: 'post' },
    "generateNewDeveloperKey": { url: '/generate-developer-api-key', method: 'post' },
    "updateDeveloperSettings": { url: '/edit-developer-settings', method: 'post' },
    "resendDeveloperKey": { url: '/resend-developer-api-key', method: 'post' },
    "deleteDeveloperKey": { url: '/delete-developer-api-key', method: 'post' },

    // redeem setting
    "getRedeemSettings": { url: '/reward-setting/redeem', method: 'get' },
    "saveRedeemSettings": { url: '/reward-setting/redeem', method: 'post' },

    // reward level
    "getRewardLevelList": { url: '/reward-level/list', method: 'post' },

    // reward type 
    "getRewardTypeList": { url: '/reward-type', method: 'post' },
    "getRewardTypeStatus": { url: '/manage-reward-type-status', method: 'post' },
    

    // staff roles
    "staffRolePermissionList": { url: '/staff-permission', method: 'post' },
    "staffRoleList": { url: '/staff-role', method: 'post' },
    "createStaffRole": { url: '/create-role', method: 'post' },
    "staffRoleInfo": { url: '/role-info', method: 'post' },
    "updateStaffRole": { url: '/edit-role', method: 'post' },
    "updateStaffRoleStatus": { url: '/manage-role-status', method: 'post' },
    "staffList": { url: '/staff', method: 'post' },
    "createStaff": { url: '/create-staff', method: 'post' },
    "staffInfo": { url: '/staff-info', method: 'post' },
    "updateStaff": { url: '/edit-staff', method: 'post' },
    "updateStaffStatus": { url: '/manage-staff-status', method: 'post' },
    // ccavenue
    "productionCCAvenueUrl": { url: '/get-ccavenue-redirect-url', method: 'post' },
    // delegate access
    "startDelegateAccess": { url: '/start-delegate-access', method: 'post' },
    "stopDelegateAccess": { url: '/stop-delegate-access', method: 'post' },
    // ticket
    "ticketList": { url: '/ticket', method: 'post' },
    "ticketInfo": { url: '/ticket-info', method: 'post' },
    "ticketTypeList": { url: '/ticket-type', method: 'post' },
    "ticketIssueTypeList": { url: '/ticket-issue-types', method: 'post' },
    "createTicket": { url: '/create-ticket', method: 'post' },
    "updateTicketStatus": { url: '/update-ticket-status', method: 'post' },
    "replyOnTicket": { url: '/reply-on-ticket', method: 'post' },
};

export default endpoints;