import CryptoJS from "crypto-js";
import { CRYPTO_SECRET_KEY } from "config/config";
import moment from "moment";
import config from "config/config";

export const encryptText = (message) => {
    message = message.toString();
    const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
    return encodeURIComponent(cipherText.toString());
};

export const decryptText = (message) => {
    var bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(message),
      CRYPTO_SECRET_KEY
    );
    return bytes.toString(CryptoJS.enc.Utf8);
};


export const checkNumberIsPositive = (number) => {
  if (number >= 0) {
    return true;
  } else {
    return false;
  }
};


export const decryptTextLaravel = (data) => {
  var key = config.LARAVEL_CRYPTO_KEY;
  let encrypted = window.atob(data);
  
  encrypted = JSON.parse(encrypted);
  const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
  const value = encrypted.value;
  key = CryptoJS.enc.Base64.parse(key);
  var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv
    });
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export const encryptObject = (obj) => {
    obj = JSON.stringify(obj);
    return CryptoJS.AES.encrypt(obj, CRYPTO_SECRET_KEY).toString();
};


export const decryptObject = (obj) => {
    return JSON.parse(
      CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8)
    );
};


export const getQueryParams = (url) => {
    if (url.indexOf("?") == -1) {
      return {};
    }
    const paramArr = url.slice(url.indexOf("?") + 1).split("&");
    const params = {};
    paramArr.map((param) => {
      const [key, val] = param.split("=");
      var queryval = val.replace(/\+/g, "%20");
      params[key] = decodeURIComponent(queryval);
    });
    return params;
};


export const allowNumberInput = (event) => {
    var key = window.event ? event.keyCode : event.which;
    if (
      key === 8 ||
      key === 46 ||
      key === 37 ||
      key === 39
    ) {
      return true;
    } else if (key < 48 || key > 57) {
      event.preventDefault();
    } else {
      return true;
    }
};


export const placeParams = (pathRegex, params) => {
    const segments = pathRegex.split("/");
    return (
      segments
        .map((segment) => {
          let offset = segment.indexOf(":?") + 1;
  
          if (offset) {
            let key = segment.slice(offset + 1);
            return params[key];
          }
  
          offset = segment.indexOf(":") + 1;
          if (!offset) return segment;
  
          let key = segment.slice(offset);
          return params[key];
        })
        .join("/")
        // Remove trailing "/"
        .replace(/\/+$/, "")
        // Remove redundant "/"
        .replace(/\/{2,}/, "/")
    );
};

export const generateRandomNumber = () => parseInt(Date.now() * Math.random());

export const capitalizeWord = (text) => {
    const words = text.split(" ");
  
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
};

export const scrollToTop = () => {
    // called for pagination and filter
    if (
      !document.querySelector(".has-filter") ||
      (document.querySelector(".has-filter") &&
        document.documentElement.scrollTop > 300)
    ) {
      document.documentElement.scrollTo(0, 0);
      if (document.querySelector(".list-section.overflow-scroll-section")) {
        document.querySelector(
          ".list-section.overflow-scroll-section"
        ).scrollTop = 0;
      }
    }
};


export const routeScrollToTop = () => {
    // called on route change
    window.$("html, body").animate({ scrollTop: 0 }, "300");
};

export const formatMobileNumber = (number) => {
  const lastFourDigits = number.slice(-4);
  const asterisks = '*'.repeat(number.length - 4);
  return asterisks + lastFourDigits;
};


export const numberFormatShort = (number, precision = 1) => {
  const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

  // determine the appropriate SI symbol for the number
  const tier = Math.log10(Math.abs(number)) / 3 | 0;
  if (tier === 0) return number;

  // calculate the short number and append the SI symbol
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaledNumber = number / scale;

  // limit the decimal places based on the precision parameter
  const formattedNumber = scaledNumber.toFixed(precision);

  return formattedNumber + suffix;
};


export const getTimeDifferenceInMinutes = (utcDateTimeString) => {
  const [dateString, timeString] = utcDateTimeString.split(' ');
  const [year, month, day] = dateString.split('-');
  const [hours, minutes, seconds] = timeString.split(':');
  
  const utcDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  const currentUtcTime = new Date();

  // Calculate the difference in minutes
  const timeDifference = Math.round((currentUtcTime - utcDateTime) / (1000 * 60));

  return timeDifference;
}


export const generateRandomFileName = (file) => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const randomString = Array.from(crypto.getRandomValues(new Uint32Array(8)))
    .map((x) => characters[x % characters.length])
    .join('');

  const timestamp = Date.now();
  const fileExtension = file.name.split('.').pop();
  const randomFileName = `${timestamp}_${randomString}.${fileExtension}`;
  return randomFileName;
};

export const allowedOnlyNumber = (event) => {
  const keyValue = event.key;
  const regex = /[0-9]/; // Only allow numbers

  if (
    !regex.test(keyValue) &&
    keyValue !== 'Backspace' &&
    keyValue !== 'Delete' && // Allow Delete key
    !(event.ctrlKey && keyValue === 'a') &&  // Allow Ctrl + A for select all
    !(event.metaKey && keyValue === 'a') &&  // Allow Cmd + A for select all (Mac)
    keyValue !== 'Tab'
  ) {
    event.preventDefault();
  }
};

export const allowedOnlyAmount = (event) => {
  const keyValue = event.key;
  const currentValue = event.target.value;
  const regex = /^\d*\.?\d*$/; // Allow numbers and decimals

  if (
    (!regex.test(keyValue) || (keyValue === '.' && currentValue.includes('.'))) &&
    keyValue !== 'Backspace' &&
    keyValue !== 'Delete' && // Allow Delete key
    !(event.ctrlKey && keyValue === 'a') &&  // Allow Ctrl + A for select all
    !(event.metaKey && keyValue === 'a') &&  // Allow Cmd + A for select all (Mac)
    keyValue !== 'Tab'
  ) {
    event.preventDefault();
  }
};

export const allowedOnlyIPAddress = (event) => {
  const keyValue = event.key;
  const regex = /^\d*\.?\d*$/; // Allow numbers and decimals

  if (
    (!regex.test(keyValue)) &&
    keyValue !== 'Backspace' &&
    keyValue !== 'Delete' && // Allow Delete key
    !(event.ctrlKey && keyValue === 'a') &&  // Allow Ctrl + A for select all
    !(event.metaKey && keyValue === 'a') &&  // Allow Cmd + A for select all (Mac)
    keyValue !== 'Tab'
  ) {
    event.preventDefault();
  }
};

export const allowedUpperCase = (event) => {
  event.target.value = event.target.value.toUpperCase();
};

export const addTripleDots = (string,limit) => {
  if (string.length > limit) {
    return `${string.slice(0, limit)}...`;
  }
  return string;
}
export const copyToClipboard = (text, callback) => {
  // Copy the text 
  navigator.clipboard.writeText(text);
  if(callback){
    callback();
  }
}
export const getHiddenAPIKey = (input, showAll=true) => {
  var mainStr = input;
  // var visibleString = mainStr.slice(-10); // end 10 characters
  var visibleString = mainStr.slice(0, 10); // starting 10 characters
  var countNum = '**********';
  if(showAll){
    countNum = "";
    for(var i = (mainStr.length)-4; i>0; i--){
      countNum += '*';
    }
  }
  var decodedKey = visibleString+countNum;
  return (decodedKey);
}