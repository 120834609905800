import { OUTLET_ROLE_SLUG } from "config/config";
import { vendorInfoSelector } from "./vendorSelectors";
import { outletInfoSelector } from "./outletSelectors";
import { OUTLET_STAFF_ROLE_SLUG, VENDOR_STAFF_ROLE_SLUG } from "config/config";

export const userLocationSelector = state => state.auth.currentLocation;
export const userInfoSelector = state => state.auth.userInfo;
export const userPermissionListSelector = state => {
    var userInfo = userInfoSelector(state);
    if(userInfo)
    {
        return userInfo.permission;
    }
    return [];
}
export const userTimeZoneSelector= state => {
    if(state.auth.userInfo)
    {
        return state.auth.userInfo.timezone;
    }
    return "Asia/Kolkata";
}
export const activeRoleSelector = state => state.vendor.activeRole;
export const activeRoleSlugSelector = state => state.vendor.activeRole?.slug;
export const userAllowedRewardSelector = state => {
    var activeRole = activeRoleSlugSelector(state);
    var allowedRewards = [];
    if (activeRole && activeRole == OUTLET_ROLE_SLUG) {
        allowedRewards = outletInfoSelector(state)?.admin_allowed_reward_permission;
    }
    else{
        allowedRewards = vendorInfoSelector(state)?.admin_allowed_reward_permission;
    }
    return allowedRewards;
}
export const userAllowedEnabledRewardSelector = state => {
    var allRewards = userAllowedRewardSelector(state);
    let tempRewardList = [];
    if(allRewards){
        allRewards.forEach(function(v) {
            tempRewardList[v.reward_type_id] = (v.is_allowed == "1" && v.is_enabled == "1") ? true : false;
        });
    }
    return tempRewardList;
}
export const userRoleNameSelector = state => {
    var activeRole = activeRoleSelector(state);
    var roleName = "";
    if (activeRole){
        if(activeRole.slug == VENDOR_STAFF_ROLE_SLUG || activeRole.slug == OUTLET_STAFF_ROLE_SLUG) {
            var userInfo = userInfoSelector(state);
            return userInfo?.staff_role_name;
        }
        else{
            roleName = activeRole.name;
        }
    }
    return roleName;
}