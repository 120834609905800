import { combineReducers } from "redux";
import SettingsReducer from "./SettingsReducer";
import AuthReducer from "./AuthReducer";
import VendorReducer from "./VendorReducer";
import OutletReducer from "./OutletReducer";

const reducers = combineReducers({
    settings: SettingsReducer,
    auth: AuthReducer,
    vendor: VendorReducer,
    outlet: OutletReducer
    //other reducers come here...
  });

  export default reducers;