import React from "react";
import Spinner from "assets/images/svg/preloader.svg";

export function PreloaderComponent() {
    return (
        <div className="loder_main">
        <section className="vh-100  my_login">
            <div className="loder_main_box">
                <div className="balls balls-1">
                    <div className="ball ball--1"></div>
                    <div className="ball ball--2"></div>
                    <div className="ball ball--3"></div>
                    <div className="ball ball--4"></div>
                </div>
                <div className="balls balls-2">
                    <div className="ball ball--1"></div>
                    <div className="ball ball--2"></div>
                    <div className="ball ball--3"></div>
                    <div className="ball ball--4"></div>
                </div>
            </div>
        </section>
        </div>
    );
}