import React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import config from "config/config";
import { loginRoutes as routes } from "config/routing";
import PublicRoute from "routes/PublicRoute";

export default function PublicLayout(props) {
    return (
        <PublicRoute>
        <div className="change-bg">
                <section className="vh-100 gradient-custom my_login">
                    <div className="container py-5 h-100">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                                <div className="card my_login_box">
                                    <div className="card-body p-5">
                                        <div className="pb-2">
                                            <Link to={routes.login.path}><img src="/images/logo_f_box.png" width="300" alt=""/></Link>
                                        </div>
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
        </PublicRoute>
    );
}