import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { successMessage } from "utils/ToastNotificationHelper";
import { errorMessage } from "utils/ToastNotificationHelper";
import { storeUserInfo } from "utils/StorageHelper";
import { connect } from "react-redux";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { storeActiveRoleInfo, removeLocalStorage } from "utils/StorageHelper";
import { stopDelegateConfirmationModalDetails } from "utils/ProjectHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { loginRoutes, mainRoutes as routes } from "config/routing";
import { stopDelegateAccess } from "store/actions/AuthAction";
import { removeUserSessionData } from "store/actions/AuthAction";

function StopDelegateDialogModal(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const modalDetails = stopDelegateConfirmationModalDetails(t);
  const { showModal, handleOnModalClose, selectedRow, userInfo } = props;
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const onFormSubmit = (data) => {
    if (!isLoading) {
      setLoading(true);
      const payload = {
        unique_session_id: userInfo.unique_id, //selectedRow.id,
      };

      var actionFunctionName = stopDelegateAccess;
      var successMsg = t("delegate_access_stopped");

      actionFunctionName(payload, (success, response) => {
        if (success) {
          const userData = userInfo.mainUser;
          const userRoles = userData.roles;
          if (userData && userRoles.length) {
            successMessage(successMsg);
            const activeRole = userRoles[0];
            var obj = {
              authorization: userData.authToken,
            };
            storeUserInfo({ ...userData, ...obj });
            storeActiveRoleInfo(activeRole);
            removeLocalStorage("vendorInfo");
            removeLocalStorage("outletInfo");
            handleOnModalClose();
            if (pathname == routes.home.path) {
              setTimeout(() => {
                document.location.reload(true);
              }, 700);
            } else {
              navigate(routes.home.path);
            }
          } else {
            errorMessage(t("something_went_wrong"));
            removeUserSessionData();
          }
        } else {
          errorMessage(response.message);
        }
        setLoading(false);
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleOnModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalDetails.title}</Modal.Title>
      </Modal.Header>
      <form method="post" onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>{modalDetails.message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant={modalDetails.closeButtonColor}
            onClick={handleOnModalClose}
          >
            {modalDetails.closeButtonText}
          </Button>
          <Button type="submit" variant={modalDetails.primaryButtonColor}>
            {isLoading ? t("loading") : modalDetails.primaryButtonText}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    //any name : selector function,
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(StopDelegateDialogModal);
