import { OUTLET_ROLE_SLUG } from "config/config";
import { encryptObject, decryptObject } from "./CommonHelper";
import { getCurrentDateTime } from "./DateHelper";
import { VENDOR_ROLE_SLUG } from "config/config";
import { SUPER_ADMIN_ROLE_SLUG } from "config/config";
import { VENDOR_STAFF_ROLE_SLUG } from "config/config";
import { OUTLET_STAFF_ROLE_SLUG } from "config/config";

export const storeLocalStorage = (key,value) => {
    localStorage.setItem(key,value);
}

export const getLocalStorage = (key) => {
    localStorage.getItem(key);
}

export const getUserInfo = () => {
    var userInfo = localStorage.getItem("userInfo");
    if(userInfo){
        userInfo = decryptObject(userInfo);
    }
    return userInfo;
}

export const getActiveRole = () => {
    var activeRole = localStorage.getItem("activeRole");
    if(activeRole){
        activeRole = decryptObject(activeRole);
    }
    return activeRole;
}
export const hasVendorRoleAccess = () => {
    var hasVendorRoleFlag = false;
    var activeRole = getActiveRole();
    hasVendorRoleFlag = (activeRole) && activeRole.slug == VENDOR_ROLE_SLUG;
    return hasVendorRoleFlag;
}
export const hasOutletRoleAccess = () => {
    var hasOutletRoleFlag = false;
    var activeRole = getActiveRole();
    hasOutletRoleFlag = (activeRole) && activeRole.slug == OUTLET_ROLE_SLUG;
    return hasOutletRoleFlag;
}
export const hasSuperAdminRoleAccess = () => {
    var hasOutletRoleFlag = false;
    var activeRole = getActiveRole();
    hasOutletRoleFlag = (activeRole) && activeRole.slug == SUPER_ADMIN_ROLE_SLUG;
    return hasOutletRoleFlag;
}
export const hasVendorStaffRoleAccess = () => {
    var hasOutletRoleFlag = false;
    var activeRole = getActiveRole();
    hasOutletRoleFlag = (activeRole) && activeRole.slug == VENDOR_STAFF_ROLE_SLUG;
    return hasOutletRoleFlag;
}
export const hasOutletStaffRoleAccess = () => {
    var hasOutletRoleFlag = false;
    var activeRole = getActiveRole();
    hasOutletRoleFlag = (activeRole) && activeRole.slug == OUTLET_STAFF_ROLE_SLUG;
    return hasOutletRoleFlag;
}
export const getOutletInfo = () => {
    var outletInfo = localStorage.getItem("outletInfo");
    if(outletInfo){
        outletInfo = decryptObject(outletInfo);
    }
    return outletInfo;
}

export const getVendorInfo = () => {
    var vendorInfo = localStorage.getItem("vendorInfo");
    if(vendorInfo){
        vendorInfo = decryptObject(vendorInfo);
    }
    return vendorInfo;
}
export const getStaffPermissionList = () => {
    var userInfo = getUserInfo();
    if(userInfo)
    {
        return userInfo.permission;
    }
    return [];
}

export const storeUserInfo = (obj) => {
    obj = {...obj, ...{"last_updated_at": getCurrentDateTime()}}
    var encryptedObj = encryptObject(obj);
    storeLocalStorage("userInfo",encryptedObj);
}

export const storeActiveRoleInfo = (obj) => {
    var encryptedObj = encryptObject(obj);
    storeLocalStorage("activeRole",encryptedObj);
}

export const storeVendorInfo = (obj) => {
    obj = {...obj, ...{"last_updated_at": getCurrentDateTime()}}
    var encryptedObj = encryptObject(obj);
    storeLocalStorage("vendorInfo",encryptedObj);
}

export const storeOutletInfo = (obj) => {
    obj = {...obj, ...{"last_updated_at": getCurrentDateTime()}}
    var encryptedObj = encryptObject(obj);
    storeLocalStorage("outletInfo",encryptedObj);
}

export const getUserAuthToken = (key) => {
    var userInfo = getUserInfo();
    var authorization = "";
    if(userInfo){
        authorization = userInfo.authorization;
    }
    return authorization;
}

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}