import AWS from "aws-sdk"; 
import LRUCache from 'lru-cache';
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_REGION, AWS_TEMP_URL_VALIDITY_HOUR, AWS_BUCKET  } from "config/config";
import { decryptText, generateRandomFileName } from "./CommonHelper";
AWS.config.update({accessKeyId: decryptText(AWS_ACCESS_KEY_ID), secretAccessKey: decryptText(AWS_SECRET_ACCESS_KEY), region: AWS_REGION });
// console.log(decryptText(AWS_ACCESS_KEY_ID), "====",decryptText(AWS_SECRET_ACCESS_KEY))
const rootPath = "public/upload";
const s3 = new AWS.S3();
const myBucket = AWS_BUCKET;
const signedUrlExpireSeconds = 3600 * AWS_TEMP_URL_VALIDITY_HOUR;

const cache = new LRUCache({
    ttl: 3600 * (AWS_TEMP_URL_VALIDITY_HOUR-1), // Maximum age of items (in milliseconds)
    ttlAutopurge: true
});

export const loadCache = () => {
    const data = localStorage.getItem('imageCache');
    if (data) {
      cache.load(JSON.parse(data));
    }
}

export const saveCache = () => {
    localStorage.setItem('imageCache', JSON.stringify(cache.dump()));
}

export const getAwsUrl = (keyFile, ignoreCache=false) => {
    const cachedUrl = cache.get(keyFile);
    if (cachedUrl && !ignoreCache) {
      // retrieved from cache
      return cachedUrl;
    }
    const url = s3.getSignedUrl('getObject', {
        Bucket: myBucket,
        Key: keyFile,
        Expires: signedUrlExpireSeconds
    })
    // setting cache
    cache.set(keyFile, url);
    return url;
}
export const deleteAwsFile = async (filename="") => {
  if(filename == ""){
    filename = "public/upload/ticket/1703497758040_pm9BVwQn.jpg";
  }
  const params = {
    Bucket: myBucket,
    Key: filename
  };
    // s3.deleteFile
    // .deleteFile(filename, config)
    // .then(response => console.log(response))
    // .catch(err => console.error(err))

    return new Promise((resolve, reject) => {
      s3.deleteObject(params, (err, data) => {
        console.log(err, data);
        if (err) {
          console.error(err);
          resolve(false);
          return;
        }
        resolve(data.Key);
      });
    });
} 
export const uploadAwsFile = async (folder="profile", file) => {
    const folderName = rootPath+'/'+folder;
    const fileName = generateRandomFileName(file);
    const keyName = `${folderName}/${fileName}`;
    const params = {
      Bucket: myBucket,
      Key: keyName,
      Body: file
    };
    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error(err);
          resolve(false);
          return;
        }
        resolve(data.Key);
      });
    });
}

export const getAwsUploadFolderPath = (type) => {
  switch (type) {
    case "profile":
      return "profile";
    case "vendor_logo":
      return "vendor/logo";
    case "vendor_banner":
      return "vendor/banners";
    case "vendor_offer":
      return "vendor/offers";
    case "gallery":
      return "vendor/photos";
    case "vendor_product":
      return "vendor/products"
    case "currency":
      return "general/currency"
    case "country":
      return "general/flag"
    case "language":
      return "general/language"
    case "payment_gateway":
      return "general/payment_gateway"
    case "ticket":
        return "ticket"
    // case "qr":
    //   return "";
    default:
      return "";
  }
}; 
