import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

export default function ConfirmDialogModal(props) {
    const {t} = useTranslation();
    const { modalDetails, showModal, handleOnConfirm, handleOnModalClose, handleConfirmLoading } = props;
    
  return (
    <Modal show={showModal} onHide={handleOnModalClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>{modalDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalDetails.message}</Modal.Body>
        <Modal.Footer>
            <Button variant={ modalDetails.closeButtonColor } onClick={handleOnModalClose}>{ modalDetails.closeButtonText }</Button>
            <Button variant={ modalDetails.primaryButtonColor } onClick={handleOnConfirm}>{ handleConfirmLoading ? t('loading') : modalDetails.primaryButtonText }</Button>
        </Modal.Footer>
    </Modal>
  )
}
