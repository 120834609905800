import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginRoutes, mainRoutes as routes } from "config/routing";
import { connect } from "react-redux";
import { pageInfoSelectors } from "components/_selectors/SettingsSelectors";
import {
  userInfoSelector,
  userRoleNameSelector,
} from "components/_selectors/authSelectors";
import { useTranslation } from "react-i18next";
import ConfirmDialogModal from "../Modals/ConfirmDialogModal";
import { successMessage } from "utils/ToastNotificationHelper";
import { logoutUser } from "store/actions/AuthAction";
import { capitalizeWord } from "utils/CommonHelper";
import { getAwsUrl } from "utils/AwsHelper";
import config from "config/config";
import StopDelegateDialogModal from "components/_common/Modals/StopDelegateDialogModal";
function Header(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo, userRoleName } = props;
  // stop delegate access
  const [
    showStopDelegateConfirmationModel,
    setShowStopDelegateConfirmationModel,
  ] = useState(false);

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isLogoutLoading, setLogoutLoading] = useState(false);

  const logoutModalDetails = {
    title: t("are_you_sure"),
    message: t(
      "do_you_want_to_logout_you_will_be_returned_to_the_login_screen"
    ),
    closeButtonColor: "secondary",
    primaryButtonColor: "danger",
    closeButtonText: t("cancel"),
    primaryButtonText: t("proceed_to_logout"),
  };

  const proceedToLogout = () => {
    setLogoutLoading(true);
    if (!isLogoutLoading) {
      logoutUser(() => {
        successMessage(t("logout_success"));
        setLogoutLoading(false);
        navigate(loginRoutes.login.path);
      });
    }
  };

  const handleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };
  // delegate access
  const handleStopDelegateAccess = () => {
    setShowStopDelegateConfirmationModel(true);
  };
  const handleOnStopDelegateAccessModalClose = () => {
    setShowStopDelegateConfirmationModel(false);
  };
  return (
    <>
      {showLogoutModal && (
        <ConfirmDialogModal
          showModal={showLogoutModal}
          modalDetails={logoutModalDetails}
          handleOnConfirm={proceedToLogout}
          handleOnModalClose={handleLogoutModal}
          handleConfirmLoading={isLogoutLoading}
        />
      )}
      {showStopDelegateConfirmationModel && (
        <StopDelegateDialogModal
          showModal={showStopDelegateConfirmationModel}
          handleOnModalClose={handleOnStopDelegateAccessModalClose}
        />
      )}
        <div className="row">
            <div className="card_plate_aln card_plate_aln_only">
                <div className="card_plate">
                    <div className="user_plate">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="mb-2 fw-bold">{ props.pageInfo?.title }</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to={routes.home.path}><i className="bi bi-house-fill"></i> Home</Link></li>
                                        {props.pageInfo?.breadcrumb.map((list, index) => {
                                            return (
                                            <React.Fragment key={"breadcrumbs"+index}>
                                                {list.isActive ? (
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page"
                                                >
                                                    {list.title}
                                                </li>
                                                ) : (
                                                <li className="breadcrumb-item">
                                                    <Link to={list.link} className="tooltip-area">
                                                    <span className="tooltiptext">{list.title}</span>
                                                    </Link>
                                                </li>
                                                )}
                                            </React.Fragment>
                                            );
                                        })}
                                    </ol>
                                </nav>
                            </div>
                            { userInfo && Object.keys(userInfo).length > 0 &&
                            <>
                                <div className="col-6">
                                    <div className="dropdown text-end">
                                        <div className="fw-bold d-none d-sm-inline-block pe-2 align-middle">
                                            <div>Hi {capitalizeWord(
                                            userInfo?.first_name + " " + userInfo?.last_name
                                        )}!</div> 
                                        <div className="truncate my_w100 float-end text-muted">{userRoleName}</div>
                                        </div>
                                        <Link className="d-inline-block py-1 dropdown-toggle px-0" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={userInfo.profile_pic ? getAwsUrl(userInfo.profile_pic) : config.DEFAULT_PROFILE_IMAGE} width="49" height="49" alt="" className="rounded-circle" loading="lazy"/> 
                                        </Link>
                                    
                                        <ul className="dropdown-menu dropdown-menu-end card_plate p-2 border-0" aria-labelledby="dropdownMenuLink">
                                            <li><Link className="dropdown-item" href="#"><span className="fw-bold d-sm-none d-block pe-2">Hi UserName!</span></Link></li>
                                            <li><Link className="dropdown-item" to={routes.home.path}>Dashboard</Link></li>
                                            <li><Link className="dropdown-item" to={routes.profile.path}>My Profile</Link></li>
                                            <li><div className="dropdown-divider"></div></li>
                                            <li><Link className="dropdown-item" onClick={() => handleLogoutModal()}>Logout</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                {userInfo.isDelegated && (
                                <div className="text-end"><button type="button" onClick={() => handleStopDelegateAccess()}  className="btn btn-danger"><i className="bi bi-back"></i>  {t("stop_delegate_access")}</button></div>
                                )} 
                            </>
                            }
                        </div>
                    </div>
                </div>
              </div>
            </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    //any name : selector function
    pageInfo: pageInfoSelectors(state),
    userInfo: userInfoSelector(state),
    userRoleName: userRoleNameSelector(state),
  };
};
export default connect(mapStateToProps, {})(Header);
