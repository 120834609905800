import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { mainRoutes as routes } from 'config/routing';
import { useTranslation } from 'react-i18next';
import { hasUserAccessFunctionality } from 'utils/PermissionHelper';
import { hasSuperAdminRoleAccess } from 'utils/StorageHelper';
import { getRewardTypesBySlug } from 'utils/ProjectHelper';
import { connect } from 'react-redux';
import { userAllowedEnabledRewardSelector } from 'components/_selectors/authSelectors';

function Sidebar(props) {
    const {allowedRewardList, isCollapseMenu, toggleNavigation} = props;
    const {t} = useTranslation();
    const { pathname } = useLocation();
    const [showProductCategoryMenu, setShowProductCategoryMenu] = useState(false);
    useEffect(() => {
        var selectedProductRewardSlug = getRewardTypesBySlug(t, "product_reward");
        setShowProductCategoryMenu(allowedRewardList && allowedRewardList[selectedProductRewardSlug.id]);
    },[allowedRewardList])
    return (
        <div className={`navigation ${isCollapseMenu ? 'act_nav' : ''}`}>
            <nav className="navbar navbar-expand-lg">
                <div className="nav_top_prof_box_cover">
                    <div className="nav_top_prof_box">
                        <div className="navi_top_box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6 title">
                                    </div>
                                    <div className="col-6 icon toggle">
                                        <Link onClick={toggleNavigation}><i className="bi bi-list"></i></Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="profile-usertitle text-center">
                                        <div className="profile_user_pic py-3">
                                            <Link to={routes.home.path}><img src={ isCollapseMenu ? "/images/logo.png" : "/images/logo_f.png" } width="230" alt=""/></Link> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><i style={{fontSize:"30px"}} className="bi bi-list"></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.home.path].includes(pathname) ? "active" : "" }`} to={routes.home.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('dashboard') }>
                                <span className="icon"><i className="bi bi-grid-fill"></i></span>
                                <span className="title">{ t('dashboard') }</span>
                            </Link>
                        </li>
                        {hasUserAccessFunctionality("outlet_module") && 
                            <li className="nav-item">
                                <Link className={`nav-link ${ [routes.outlet.path,routes.outletAdd.path,routes.outletEdit.path].includes(pathname) ? "active" : "" }`} to={routes.outlet.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('outlet') }>
                                    <span className="icon"><i className="bi bi-shop"></i></span>
                                    <span className="title">{ t('outlet') }</span>
                                </Link>
                            </li>
                        }
                        {hasUserAccessFunctionality("staffing_module") && 
                        <li className="nav-item dropdown">
                            <Link className={`nav-link ${ [
                                routes.staffing.path,
                                routes.staffingAdd.path,
                                routes.staffingEdit.path,
                                routes.role.path,routes.roleAdd.path,routes.roleEdit.path
                                ].includes(pathname) ? "active" : "" }`} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="icon"><i className="bi bi-person-bounding-box"></i></span>
                                <span className="title">{ t('staffing') }</span>
                                <span className="icon arrow_down"><i className="bi bi-caret-down-fill"></i></span>
                                <span className="icon arrow_up"><i className="bi bi-caret-up-fill"></i></span>
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to={routes.role.path}>{ t('role') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.staffing.path}>{ t('staffing_list') }</Link></li>
                                </ul>
                        </li>
                        }
                        { hasSuperAdminRoleAccess() &&
                        <li className="nav-item dropdown">
                            <Link className={`nav-link ${ [
                                routes.vendorIndustry.path,
                                routes.vendorVerification.path,
                                routes.vendors.path,
                                routes.vendorIndustryAdd.path,
                                routes.vendorIndustryEdit.path
                                ].includes(pathname) ? "active" : "" }`} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="icon"><i className="bi bi-person-bounding-box"></i></span>
                                <span className="title">{ t('vendors') }</span>
                                <span className="icon arrow_down"><i className="bi bi-caret-down-fill"></i></span>
                                <span className="icon arrow_up"><i className="bi bi-caret-up-fill"></i></span>
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to={routes.vendorIndustry.path}>{ t('industry') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.vendors.path}>{ t('vendors') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.vendorVerification.path}>{ t('vendor_verification') }</Link></li>
                                </ul>
                        </li>
                        }
                        {/* <li className="nav-item">
                            <Link className={`nav-link ${ [routes.role.path,routes.roleAdd.path,routes.roleEdit.path].includes(pathname) ? "active" : "" }`} to={routes.role.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('role') }>
                                <span className="icon"><i className="bi bi-shop"></i></span>
                                <span className="title">{ t('role') }</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.staffing.path,routes.staffingAdd.path,routes.staffingEdit.path].includes(pathname) ? "active" : "" }`} to={routes.staffing.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('staffing') }>
                                <span className="icon"><i className="bi bi-shop"></i></span>
                                <span className="title">{ t('staffing_list') }</span>
                            </Link>
                        </li> */}
                        {(hasUserAccessFunctionality("admin_settings") || hasUserAccessFunctionality("vendor_wise_settings") || hasUserAccessFunctionality("outlet_settings") || hasUserAccessFunctionality("photo_gallery_settings")) && 
                        <li className="nav-item dropdown">
                            <Link className={`nav-link ${ [
                                routes.generalSettings.path,
                                routes.brandSettings.path,
                                routes.loyaltyCoinSettings.path,
                                routes.rewardLevelSettings.path,
                                routes.rewardLevelSettingsAdd.path,
                                routes.rewardLevelSettingsEdit.path,
                                routes.mobileApplicationSettings.path,
                                routes.rewardSettings.path,
                                routes.levelupRewardSettings.path,
                                routes.followRewardSettings.path,
                                routes.ratingRewardSettings.path,
                                routes.productRewardSettings.path,
                                routes.generalRewardSettings.path,
                                routes.photoGallery.path,
                                routes.developerSettings.path,
                                routes.redeemSettings.path,
                                routes.country.path,
                                routes.state.path,
                                routes.city.path,
                                routes.language.path,
                                routes.paymentGateway.path,
                                routes.taxClass.path,
                                routes.rewardType.path,
                                routes.content.path,
                                routes.planMaster.path,
                                routes.generalMaster.path,
                                routes.locationMaster.path
                                ].includes(pathname) ? "active" : "" }`} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="icon"><i className="bi bi-gear"></i></span>
                                <span className="title">{ t('settings') }</span>
                                <span className="icon arrow_down"><i className="bi bi-caret-down-fill"></i></span>
                                <span className="icon arrow_up"><i className="bi bi-caret-up-fill"></i></span>
                            </Link>
                            
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {hasUserAccessFunctionality("vendor_wise_settings") && 
                                <>
                                {hasUserAccessFunctionality("vendor_wise_general_settings") && (
                                <li><Link className="dropdown-item" to={routes.generalSettings.path}>{ t('general_settings') }</Link></li>
                                )}
                                {hasUserAccessFunctionality("vendor_wise_brand_settings") && (
                                <li><Link className="dropdown-item" to={routes.brandSettings.path}>{ t('brand_settings') }</Link></li>
                                )}
                                {hasUserAccessFunctionality("vendor_wise_loyalty_coin_settings") && (
                                <li><Link className="dropdown-item" to={routes.loyaltyCoinSettings.path}>{ t('loyalty_coin_settings') }</Link></li>
                                )}
                                {hasUserAccessFunctionality("vendor_wise_reward_level_settings") && (
                                <li><Link className="dropdown-item" to={routes.rewardLevelSettings.path}>{ t('reward_level_settings') }</Link></li>
                                )}
                                {/* <li><Link className="dropdown-item" to={routes.redeemSettings.path}>{ t('redeem_settings') }</Link></li> */}
                                {hasUserAccessFunctionality("vendor_wise_mobile_application_settings") && (
                                <li><Link className="dropdown-item" to={routes.mobileApplicationSettings.path}>{ t('mobile_application_settings') }</Link></li>
                                )}
                                {hasUserAccessFunctionality("vendor_wise_reward_settings") && (
                                <li><Link className="dropdown-item" to={routes.rewardSettings.path}>{ t('reward_settings') }</Link></li>
                                )}
                                {hasUserAccessFunctionality("vendor_wise_developer_settings") && (
                                <li><Link className="dropdown-item" to={routes.developerSettings.path}>{ t('developer_settings') }</Link></li>
                                )}
                                </>
                                }
                                {hasUserAccessFunctionality("outlet_settings") && 
                                    <li><Link className="dropdown-item" to={routes.outletSettings.path}>{ t('general_settings') }</Link></li>
                                }
                                {hasUserAccessFunctionality("photo_gallery_settings") && 
                                    <li><Link className="dropdown-item" to={routes.photoGallery.path}>{ t('photo_gallery') }</Link></li>
                                }
                                {hasUserAccessFunctionality("admin_settings") &&
                                <>
                                <li><Link className="dropdown-item" to={routes.generalMaster.path} >{ t('general') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.locationMaster.path} >{ t('location_master') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.planMaster.path} >{ t('plan_master') }</Link></li>
                                {/* <li><Link className="dropdown-item" to={routes.country.path} >{ t('country') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.state.path}>{ t('state') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.city.path}>{ t('city') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.language.path}>{ t('languages') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.paymentGateway.path} >{ t('payment_gateway') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.taxClass.path}>{ t('tax_class') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.rewardType.path}>{ t('reward_type') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.content.path}>{ t('content_pages') }</Link></li>
                                <li><Link className="dropdown-item" to={routes.membershipplan.path}>{ t('membership_plan') }</Link></li> */}
                                </>
                                }
                            </ul>
                        </li>
                        }
                        {hasUserAccessFunctionality("customer_module") &&
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.customers.path,routes.customerDetail.path].includes(pathname) ? "active" : "" }`} to={routes.customers.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('customers') }>
                                <span className="icon"><i className="bi bi-people"></i></span>
                                <span className="title">{ t('customers') }</span>
                            </Link>
                        </li>
                        }
                        {hasUserAccessFunctionality("rp_transaction_module") &&
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.rpTransaction.path].includes(pathname) ? "active" : "" }`} to={routes.rpTransaction.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('rp_transactions') }>
                                <span className="icon"><i className="bi bi-calendar"></i></span>
                                <span className="title">{ t('rp_transactions') }</span>
                            </Link>
                        </li>
                        }
                        {showProductCategoryMenu && 
                            <>
                            {hasUserAccessFunctionality("product_category_module") &&
                            <li className="nav-item">
                                <Link className={`nav-link ${ [routes.categories.path,routes.categoryAdd.path,routes.categoryEdit.path].includes(pathname) ? "active" : "" }`} to={routes.categories.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('categories') }>
                                    <span className="icon"><i className="bi bi-ui-checks"></i></span>
                                    <span className="title">{ t('categories') }</span>
                                </Link>
                            </li>
                            }
                            {hasUserAccessFunctionality("product_module") &&
                            <li className="nav-item">
                                <Link className={`nav-link ${ [routes.products.path,routes.productAdd.path,routes.productEdit.path].includes(pathname) ? "active" : "" }`} to={routes.products.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('products') }>
                                    <span className="icon"><i className="bi bi-archive"></i></span>
                                    <span className="title">{ t('products') }</span>
                                </Link>
                            </li>
                            }
                            </>
                        }
                        {hasUserAccessFunctionality("offer_module") &&
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.offers.path,routes.offerAdd.path,routes.offerEdit.path].includes(pathname) ? "active" : "" }`} to={routes.offers.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('offers') }>
                                <span className="icon"><i className="bi bi-gift"></i></span>
                                <span className="title">{ t('offers') }</span>
                            </Link>
                        </li>
                        }
                        {hasUserAccessFunctionality("subscription_module") && 
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.subscriptions.path].includes(pathname) ? "active" : "" }`} to={routes.subscriptions.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('my_subscription') }>
                                <span className="icon"><i className="bi bi-person-lines-fill"></i></span>
                                <span className="title">{ t('my_subscription') }</span>
                            </Link>
                        </li>
                        }
                        {hasUserAccessFunctionality("invoice_module") && 
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.invoices.path].includes(pathname) ? "active" : "" }`} to={routes.invoices.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('invoices') }>
                                <span className="icon"><i className="bi bi-kanban"></i></span>
                                <span className="title">{ t('invoices') }</span>
                            </Link>
                        </li>
                        }
                        {hasUserAccessFunctionality("transactions_module") && 
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.transactions.path].includes(pathname) ? "active" : "" }`} to={routes.transactions.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('transactions') }>
                                <span className="icon"><i className="bi bi-file-ruled"></i></span>
                                <span className="title">{ t('transactions') }</span>
                            </Link>
                        </li>
                        }
                        {hasUserAccessFunctionality("outlet_module") && 
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.vendorRatings.path].includes(pathname) ? "active" : "" }`} to={routes.vendorRatings.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('outlet') }>
                                <span className="icon"><i className="bi bi-shop"></i></span>
                                <span className="title">{ t('ratings') }</span>
                            </Link>
                        </li>
                        }
                        {(hasUserAccessFunctionality("admin_reports")) && 
                        <li className="nav-item dropdown">
                            <Link className={`nav-link ${ [routes.otpMaster.path,routes.smsMaster.path].includes(pathname) ? "active" : "" }`} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="icon"><i className="bi bi-gear"></i></span>
                                <span className="title">{ t('reports') }</span>
                                <span className="icon arrow_down"><i className="bi bi-caret-down-fill"></i></span>
                                <span className="icon arrow_up"><i className="bi bi-caret-up-fill"></i></span>
                            </Link>
                            
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to={routes.otpMaster.path} >{ t('otp_master') }</Link></li>
                                    <li><Link className="dropdown-item" to={routes.smsMaster.path}>{ t('sms_master') }</Link></li>
                                    
                            </ul>
                        </li>
                        }
                        {(hasUserAccessFunctionality("ticket_list")) && 
                        <li className="nav-item">
                            <Link className={`nav-link ${ [routes.tickets.path].includes(pathname) ? "active" : "" }`} to={routes.tickets.path} data-bs-toggle="tooltip" data-bs-placement="right" title={ t('outlet') }>
                                <span className="icon"><i className="bi bi-shop"></i></span>
                                <span className="title">{ t('help_and_suppoert') }</span>
                            </Link>
                        </li>
                        }
                    </ul>
                </div>
            </nav>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
      allowedRewardList: userAllowedEnabledRewardSelector(state),
    };
  };
export default connect(mapStateToProps, {})(Sidebar);