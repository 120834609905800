import React, { lazy } from "react";

//auth
const LoginPage = lazy(() => import("pages/Login"));
const ForgotPasswordPage = lazy(() => import("pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("pages/ResetPassword"));

//home
const HomePage = lazy(() => import("pages/Home"));

//profile activation history
const ActivationHistoryPage = lazy(() => import("pages/AccountActivationHistory"));

//profile
const ProfilePage = lazy(() => import("pages/Profile"));

//outlet
const OutletListPage = lazy(() => import("pages/Outlets"));
const OutletAddPage = lazy(() => import("pages/Outlets/AddOutlet"));
const OutletEditPage = lazy(() => import("pages/Outlets/EditOutlet"));

// vendor Rating
const VendorRatingListPage = lazy(() => import("pages/VendorRating"));

// Staffing
const StaffingListPage = lazy(() => import("pages/Staffing"));
const StaffingAddPage = lazy(() => import("pages/Staffing/AddStaffing"));
const StaffingEditPage = lazy(() => import("pages/Staffing/EditStaffing"));

// Vendor Industry
const VendorIndustryListPage = lazy(() => import("pages/Vendor/Industry"));
const VendorIndustryAddPage = lazy(() => import("pages/Vendor/Industry/AddIndustry"));
const VendorIndustryEditPage = lazy(() => import("pages/Vendor/Industry/EditIndustry"));
// vendor
const VendorListPage = lazy(() => import("pages/Vendor/VendorList"));
const VendorAddPage = lazy(() => import("pages/Vendor/VendorList/AddVendor"));
const VendorEditPage = lazy(() => import("pages/Vendor/VendorList/EditVendor"));
const VendorDetailPage = lazy(() => import("pages/Vendor/VendorList/ViewVendor"));
// vendor verification
const VendorVerificationListPage = lazy(() => import("pages/Vendor/PendingApproval"));
// location
const LocationMasterPage = lazy(() => import("pages/Settings/LocationMaster"));
// plan master
const PlanMasterPage = lazy(() => import("pages/Settings/PlanMaster"));
// general master
const GeneralMasterPage = lazy(() => import("pages/Settings/GeneralMaster"));
//State
const StatePage  = lazy(() => import("pages/Settings/State"));
const AddState = lazy(() => import("pages/Settings/State/AddState"));
const EditState = lazy(() => import("pages/Settings/State/EditState"));
//Country
const CountryPage = lazy(() => import("pages/Settings/Country"));
const AddCountry  = lazy(() => import("pages/Settings/Country/AddCountry"));
const EditCountry = lazy(() => import("pages/Settings/Country/EditCountry"));
//city
const CityPage  = lazy(() => import("pages/Settings/City"));
const AddCity  = lazy(() => import("pages/Settings/City/AddCity"));
const EditCity = lazy(() => import("pages/Settings/City/EditCity"));


// Role
const RoleListPage = lazy(() => import("pages/Role"));
const RoleAddPage = lazy(() => import("pages/Role/AddRole"));
const RoleEditPage = lazy(() => import("pages/Role/EditRole"));

//settings
const GeneralSettingsPage = lazy(() => import("pages/Settings/GeneralSettings"));
const BrandSettingsPage = lazy(() => import("pages/Settings/BrandSettings"));
const LoyaltyCoinSettingsPage = lazy(() => import("pages/Settings/LoyaltyCoinSettings"));
const RewardLevelListPage = lazy(() => import("pages/Settings/RewardLevel"));
const RewardLevelAddPage = lazy(() => import("pages/Settings/RewardLevel/AddRewardLevel"));
const RewardLevelEditPage = lazy(() => import("pages/Settings/RewardLevel/EditRewardLevel"));
const MobileApplicationSettingsPage = lazy(() => import("pages/Settings/MobileApplicationSettings"));
const RewardSettingsPage = lazy(() => import("pages/Settings/RewardSetting"));
const LevelupRewardSettingsPage = lazy(() => import("pages/Settings/RewardSetting/LevelupRewardSettings"));
const FollowRewardSettingsPage = lazy(() => import("pages/Settings/RewardSetting/FollowRewardSettings"));
const RatingRewardSettingsPage = lazy(() => import("pages/Settings/RewardSetting/RatingRewardSettings"));
const ProductRewardSettingsPage = lazy(() => import("pages/Settings/RewardSetting/ProductRewardSettings"));
const GeneralRewardSettingsPage = lazy(() => import("pages/Settings/RewardSetting/GeneralRewardSettings"));
const PhotoGalleryPage = lazy(() => import("pages/Settings/PhotoGallery"));
const DeveloperSettingPage = lazy(() => import("pages/Settings/DeveloperSettings"));
const RedeemSettingsPage = lazy(() => import("pages/Settings/RedeemSettings"));
const OutletSettingsPage = lazy(() => import("pages/Settings/OutletSettings"));
const SmsMasterPage = lazy(() => import("pages/Settings/Reports/SmsMaster"));
const OtpMasterPage = lazy(() => import("pages/Settings/Reports/OtpMaster"));


//PaymentGatewayPage
const PaymentGatewayPage = lazy(() => import("pages/Settings/PaymentGateway"));
const PaymentGatewayEditPage = lazy(() => import("pages/Settings/PaymentGateway/EditPaymentGateway"));

//customers
const CustomersPage = lazy(() => import("pages/Customers/CustomerList"));
const CustomerDetailPage = lazy(() => import("pages/Customers/CustomerDetail"));
//Reward Type
const RewardTypePage = lazy(() => import("pages/Settings/RewardType"));
const RewardTypeEditPage = lazy(() => import("pages/Settings/RewardType/EditRewardType"));
//tax class
const TaxClassPage = lazy(() => import("pages/Settings/TaxClass"));
const TaxClassAddPage = lazy(() => import("pages/Settings/TaxClass/AddTaxClass"));
const TaxClassEditPage = lazy(() => import("pages/Settings/TaxClass/EditTaxClass"));
//Language
const LanguagePage = lazy(() => import("pages/Settings/Language"));
const LanguagAddePage = lazy(() => import("pages/Settings/Language/AddLanguage"));
const LanguagEditePage = lazy(() => import("pages/Settings/Language/EditLanguage"));


//Contant
const ContentPage = lazy(() => import("pages/Settings/ContentPage"));
const AddContentPage = lazy(() => import("pages/Settings/ContentPage/AddContentPage"));
const EditContentPage = lazy(() => import("pages/Settings/ContentPage/EditContentPage"));
//const MemberShipPlan
const MemberShipPlanPage = lazy(() => import("pages/Settings/MemberShipPlan"));
const MemberShipPlanAddPage = lazy(() => import("pages/Settings/MemberShipPlan/AddMemberShipPlan"));
const MemberShipPlanEditPage = lazy(() => import("pages/Settings/MemberShipPlan/EditMemberShipPlan"));

//Categories
const CategoryPage = lazy(() => import("pages/Category"));
const CategoryAddPage = lazy(() => import("pages/Category/AddCategory"));
const CategoryEditPage = lazy(() => import("pages/Category/EditCategory"));

//Products
const ProductPage = lazy(() => import("pages/Product"));
const ProductAddPage = lazy(() => import("pages/Product/AddProduct"));
const ProductEditPage = lazy(() => import("pages/Product/EditProduct"));

//Offers
const OfferPage = lazy(() => import("pages/Offer"));
const OfferAddPage = lazy(() => import("pages/Offer/AddOffer"));
const OfferEditPage = lazy(() => import("pages/Offer/EditOffer"));

//RP Transactions
const RPTransactionPage = lazy(() => import("pages/RPTransaction"));

//Subscriptions
const SubscriptionPage = lazy(() => import("pages/Subscription"));

//Invoice
const InvoicePage = lazy(() => import("pages/Invoice"));

//Transactions
const TransactionPage = lazy(() => import("pages/Transaction"));

//My Plan
const MyPlanPage = lazy(() => import("pages/Subscription/MyPlan"));

// ticket
const TicketPage = lazy(() => import("pages/Ticket"));
const TicketAddPage = lazy(() => import("pages/Ticket/AddTicket"));
const TicketDetailPage = lazy(() => import("pages/Ticket/TicketDetail"));

export const loginRoutes =  {
    "login":{ "path": "/login", "element": <LoginPage /> },
    "forgotPassword":{ "path": "/forgot-password", "element": <ForgotPasswordPage /> },
    "resetPassword":{ "path": "/reset-password", "element": <ResetPasswordPage /> },
}


export const mainRoutes =  {
    "home":{ "path": "/", "element": <HomePage /> },
    "accountActivationHistory":{ "path": "/profile-activation-history", "element": <ActivationHistoryPage /> },

    //profile
    "profile":{ "path": "/profile", "element": <ProfilePage /> },

    //staffing
    "staffing":{ "path": "/staffing", "element": <StaffingListPage /> },
    "staffingAdd":{ "path": "/staffing/add", "element": <StaffingAddPage /> },
    "staffingEdit":{ "path": "/staffing/edit/:id", "element": <StaffingEditPage /> },

    //Vendor Industry
    "vendorIndustry":{ "path": "/vendor-industry", "element": <VendorIndustryListPage /> },
    "vendorIndustryAdd":{ "path": "/vendor-industry/add", "element": <VendorIndustryAddPage /> },
    "vendorIndustryEdit":{ "path": "/vendor-industry/edit/:id", "element": <VendorIndustryEditPage /> },
    // vendors
    "vendors":{ "path": "/vendors", "element": <VendorListPage /> },
    "vendorAdd":{ "path": "/vendors/add", "element": <VendorAddPage /> },
    "vendorEdit":{ "path": "/vendors/edit/:id", "element": <VendorEditPage /> },
    "vendorDetail":{ "path": "/vendors/detail/:id", "element": <VendorDetailPage /> },
    //vendor verification
    "vendorVerification":{ "path": "/pending-verification", "element": <VendorVerificationListPage /> },
    // location master
    "locationMaster": { "path": "/location-master", "element": < LocationMasterPage/> },
    "planMaster": { "path": "/plan-master", "element": < PlanMasterPage/> },
    "generalMaster": { "path": "/general-master", "element": < GeneralMasterPage/> },
    
    // State
    "state": { "path": "/state", "element": < StatePage/> },
    "StateAdd": { "path": "/create-state", "element": <AddState/> },
    "StateEdit": { "path": "/edit-state/:id", "element": <EditState/> },
    //Country
    "country":{ "path": "/country", "element": < CountryPage/> },
    "countryAdd":{ "path": "/create-country", "element": <AddCountry/> },
    "countryEdit":{"path":"/edit-country/:id","element":<EditCountry/>},
   
    //city
    "city": { "path": "/city", "element": < CityPage/> },
    "cityAdd": { "path": "/create-city", "element": <AddCity/> },
    "cityEdit":{"path":"/edit-city/:id","element":<EditCity/>},
   



    "role":{ "path": "/role", "element": <RoleListPage /> },
    "roleAdd":{ "path": "/role/add", "element": <RoleAddPage /> },
    "roleEdit":{ "path": "/role/edit/:id", "element": <RoleEditPage /> },

    //outlet
    "outlet":{ "path": "/outlet", "element": <OutletListPage /> },
    "outletAdd":{ "path": "/outlet/add", "element": <OutletAddPage /> },
    "outletEdit":{ "path": "/outlet/edit/:id", "element": <OutletEditPage /> },

    //vendor rating
    "vendorRatings":{ "path": "/rating", "element": <VendorRatingListPage /> },

    //settings
    "generalSettings":{ "path": "/general-settings", "element": <GeneralSettingsPage /> },
    "brandSettings":{ "path": "/brand-settings", "element": <BrandSettingsPage /> },
    "loyaltyCoinSettings":{ "path": "/loyalty-coin-settings", "element": <LoyaltyCoinSettingsPage /> },
    "rewardLevelSettings":{ "path": "/reward-level-settings", "element": <RewardLevelListPage /> },
    "rewardLevelSettingsAdd":{ "path": "/reward-level-settings/add", "element": <RewardLevelAddPage /> },
    "rewardLevelSettingsEdit":{ "path": "/reward-level-settings/edit/:id", "element": <RewardLevelEditPage /> },
    "mobileApplicationSettings":{ "path": "/mobile-application-settings", "element": <MobileApplicationSettingsPage /> },
    "rewardSettings":{ "path": "/reward-settings", "element": <RewardSettingsPage /> },
    "levelupRewardSettings":{ "path": "/levelup-reward-settings", "element": <LevelupRewardSettingsPage /> },
    "followRewardSettings":{ "path": "/follow-reward-settings", "element": <FollowRewardSettingsPage /> },
    "ratingRewardSettings":{ "path": "/rating-reward-settings", "element": <RatingRewardSettingsPage /> },
    "productRewardSettings":{ "path": "/product-reward-settings", "element": <ProductRewardSettingsPage /> },
    "generalRewardSettings":{ "path": "/general-reward-settings", "element": <GeneralRewardSettingsPage /> },
    "photoGallery":{ "path": "/photo-gallery", "element": <PhotoGalleryPage /> },
    "developerSettings":{ "path": "/developer-settings", "element": <DeveloperSettingPage /> },
    "redeemSettings":{ "path": "/redeem-settings", "element": <RedeemSettingsPage /> },
    "outletSettings":{ "path": "/outlet-settings", "element": <OutletSettingsPage /> },
    "smsMaster":{ "path": "/sms-master", "element": <SmsMasterPage /> },
    "otpMaster":{ "path": "/otp-master", "element": <OtpMasterPage /> },
    
   
   //paymentGateway
   "paymentGateway":{ "path": "/payment-gateway", "element": <PaymentGatewayPage/> },
   "paymentGatewayEdit":{ "path": "/create-payment-gateway/:id", "element": <PaymentGatewayEditPage/> },

    //Language
    "language": { "path": "/language", "element": < LanguagePage/> },
    "addLanguagePage": { "path": "/create-language", "element": < LanguagAddePage/> },
    "editLanguagePage": { "path": "/edit-language/:id", "element": < LanguagEditePage/> },

    //customer
    "customers":{ "path": "/customers", "element": <CustomersPage /> },
    "customerDetail":{ "path": "/customer/:id", "element": <CustomerDetailPage /> },

    //RP Transactions
    "rpTransaction":{ "path": "/rp-transactions", "element": <RPTransactionPage /> },
    //tax class
    "taxClass":{ "path": "/tax-class", "element": <TaxClassPage/> },
    "taxClassAdd":{ "path": "/create-tax-class", "element": <TaxClassAddPage/> },
    "taxClassEdit":{ "path": "/edit-tax-class/:id", "element": <TaxClassEditPage/> },
    

    //Reward Type
    "rewardType":{ "path": "/reward-type", "element": <RewardTypePage/> },
    "rewardTypeEdit":{ "path": "/edit-reward-type/:id", "element": <RewardTypeEditPage/> },
    //Contant
    "content":{ "path": "/content-pages", "element": <ContentPage/> },
    "contentAdd":{ "path": "/create-content-pages", "element": <AddContentPage/> },
    "contentEdit":{ "path": "/edit-content-pages/:id", "element": <EditContentPage/> },
    //MemberShipPlan
    "membershipplan":{ "path": "/membership-plan", "element": < MemberShipPlanPage/> },
    "membershipplanAdd":{ "path": "/create-membership-plan", "element": < MemberShipPlanAddPage/> },
    "membershipplanEdit":{ "path": "/edit-membership-plan/:id", "element": < MemberShipPlanEditPage/> },
   

    //Categories
    "categories":{ "path": "/categories", "element": <CategoryPage /> },
    "categoryAdd":{ "path": "/add-category", "element": <CategoryAddPage /> },
    "categoryEdit":{ "path": "/edit-category/:id", "element": <CategoryEditPage /> },

    //Products
    "products":{ "path": "/products", "element": <ProductPage /> },
    "productAdd":{ "path": "/add-product", "element": <ProductAddPage /> },
    "productEdit":{ "path": "/edit-product/:id", "element": <ProductEditPage /> },

    //Offers
    "offers":{ "path": "/offers", "element": <OfferPage /> },
    "offerAdd":{ "path": "/add-offer", "element": <OfferAddPage /> },
    "offerEdit":{ "path": "/edit-offer/:id", "element": <OfferEditPage /> },

    //Subscriptions
    "subscriptions":{ "path": "/subscriptions", "element": <SubscriptionPage /> },

    //Invoice
    "invoices":{ "path": "/invoices", "element": <InvoicePage /> },

    //Transactions
    "transactions":{ "path": "/transactions", "element": <TransactionPage /> },

    //Transactions
    "myPlan":{ "path": "/my-plan", "element": <MyPlanPage /> },

    //tickets
    "tickets":{ "path": "/tickets", "element": <TicketPage/> },
    "ticketAdd":{ "path": "/create-ticket", "element": <TicketAddPage/> },
    "ticketDetail":{ "path": "/ticket-detail/:id", "element": <TicketDetailPage/> },
}