const initialstate = {
    pageInfo: {
        title : "Home",
        breadcrumb : []
    }
};

const SettingsReducer  = (state = initialstate, action) => {
    switch (action.type) {
        case "UPDATE_PAGE_INFO":
            return {
                ...state,
                pageInfo: action.payload
            };
        default:
            return state;
    }
};

export default SettingsReducer;