module.exports = {
    CRYPTO_SECRET_KEY: "sbkriyansilverxis",
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    API_URL: process.env.REACT_APP_BACKEND_API_URL,
    LARAVEL_CRYPTO_KEY: process.env.REACT_APP_LARAVEL_CRYPTO_KEY,
    AWS_TEMP_URL_VALIDITY_HOUR:24,
    AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    AWS_REGION: process.env.REACT_APP_AWS_REGION,
    AWS_BUCKET: process.env.REACT_APP_AWS_BUCKET,
    DEFAULT_UPLOAD_IMAGE: "/images/default-image.png",
    DEFAULT_PROFILE_IMAGE: '/images/user_404.jpg',
    DEFAULT_COUNTRY_IMAGE: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MSA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjczMjMgMzIuNTQ0N1YyNS44NzhIMjYuNzMyM0wyMC4wNjU2IDE3LjU0NDdMMTMuMzk4OSAyNS44NzhIMTguMzk4OVYzMi41NDQ3SDIxLjczMjNaIiBmaWxsPSIjNjYzMzk5Ii8+CjxwYXRoIGQ9Ik0xMS43MzIzIDMyLjU0NDVIMTUuMDY1NlYyOS4yMTEySDExLjczMjNDOC45NzU1OSAyOS4yMTEyIDYuNzMyMjYgMjYuOTY3OCA2LjczMjI2IDI0LjIxMTJDNi43MzIyNiAyMS44NzEyIDguNzMwNTkgMTkuNjE3OCAxMS4xODczIDE5LjE4NjJMMTIuMTU1NiAxOS4wMTYyTDEyLjQ3NTYgMTguMDg2MkMxMy42NDczIDE0LjY2NzggMTYuNTU3MyAxMi41NDQ1IDIwLjA2NTYgMTIuNTQ0NUMyNC42NjA2IDEyLjU0NDUgMjguMzk4OSAxNi4yODI4IDI4LjM5ODkgMjAuODc3OFYyMi41NDQ1SDMwLjA2NTZDMzEuOTAzOSAyMi41NDQ1IDMzLjM5ODkgMjQuMDM5NSAzMy4zOTg5IDI1Ljg3NzhDMzMuMzk4OSAyNy43MTYyIDMxLjkwMzkgMjkuMjExMiAzMC4wNjU2IDI5LjIxMTJIMjUuMDY1NlYzMi41NDQ1SDMwLjA2NTZDMzMuNzQyMyAzMi41NDQ1IDM2LjczMjMgMjkuNTU0NSAzNi43MzIzIDI1Ljg3NzhDMzYuNzI5NyAyNC4zODM5IDM2LjIyNjYgMjIuOTMzOCAzNS4zMDM0IDIxLjc1OTJDMzQuMzgwMiAyMC41ODQ3IDMzLjA5IDE5Ljc1MzMgMzEuNjM4OSAxOS4zOTc5QzMwLjkxMDYgMTMuNjYxMiAyNS45OTg5IDkuMjExMTggMjAuMDY1NiA5LjIxMTE4QzE1LjQ3MjMgOS4yMTExOCAxMS40ODIzIDExLjg5NjIgOS42NjA1OSAxNi4xMjc4QzYuMDgwNTkgMTcuMTk3OCAzLjM5ODkzIDIwLjU3NzggMy4zOTg5MyAyNC4yMTEyQzMuMzk4OTMgMjguODA2MiA3LjEzNzI2IDMyLjU0NDUgMTEuNzMyMyAzMi41NDQ1WiIgZmlsbD0iIzY2MzM5OSIvPgo8L3N2Zz4K",
    DEFAULT_LOGO_IMAGE: '/images/Rectangle 1925.png',
    DEFAULT_BANNER_IMAGE: '/images/banner_placeholder.png',
    DEFAULT_PRODUCT_IMAGE: '/images/product_placeholder.png',
    DEFAULT_MARKER_IMAGE: '/images/marker.png',
    REWARD_OPTION_TYPE:[
        {"slug": "product", name:"product"},
        {"slug": "point", name:"point"},
        {"slug": "both", name:"both"}
    ],
    VENDOR_INFO_REFRESH_EVERY_MINUTES: 10,
    OUTLET_INFO_REFRESH_EVERY_MINUTES: 10,
    LOGGEDIN_USER_INFO_REFRESH_EVERY_MINUTES:10,
    VENDOR_ROLE_SLUG: "vendor",
    OUTLET_ROLE_SLUG: "outlet",
    SUPER_ADMIN_ROLE_SLUG: "super_admin",
    VENDOR_STAFF_ROLE_SLUG: "vendor_staff",
    OUTLET_STAFF_ROLE_SLUG: "outlet_staff",
    DATETIME_ALLOW_UTC_TIMEZONE: process.env.REACT_APP_ALLOW_UTC_TIMEZONE || true,
    SERVER_TIMEZONE:process.env.REACT_APP_SERVER_TIMEZONE,
    // ahmedabad location
    DEFAULT_LOCATION: {
        lat: 23.022894, 
        lng: 72.5073598
      },
}