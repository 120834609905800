import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { mainRoutes, loginRoutes } from "config/routing";
import { PreloaderComponent } from "components/_common/Preloader";
import AppLayout from "layouts/AppLayout";
import NotFound from "components/_common/NotFound";
import PublicLayout from "layouts/PublicLayout";


export default function AppRouteWrapper() {
  var routeKeys = Object.keys(mainRoutes);
  var loginRouteKeys = Object.keys(loginRoutes);
  return (
    <Suspense fallback={<PreloaderComponent />}>
      <Routes>
          <Route element={<AppLayout />}>
            {routeKeys.map((routeKey, i) => {
              return (
                <Route
                  key={i}
                  path={mainRoutes[routeKey].path}
                  element={mainRoutes[routeKey].element}
                />
              );
            })}
          </Route>
          <Route element={<PublicLayout />}>
              {loginRouteKeys.map((routeKey, i) => {
              return (
                <Route
                  key={i}
                  path={loginRoutes[routeKey].path}
                  element={loginRoutes[routeKey].element}
                />
              );
            })}
          </Route>
          <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}