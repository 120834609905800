import 'react-notifications/lib/notifications.css';
import { useEffect, useLayoutEffect } from 'react';
import ErrorBoundary from 'components/_common/ErrorBoundary/ErrorBoundary';
import {HelmetProvider} from 'react-helmet-async';
import {NotificationContainer} from 'react-notifications';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';
import { routeScrollToTop } from 'utils/CommonHelper';
import AppRouteWrapper from './routes/AppRouteWrapper';
import { getCurrentLocation } from 'store/actions/AuthAction';



const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
  };

// scroll to top on route change
const Wrapper = ({children}) => {
    // const location = useLocation();
    // const isPop = useBackButton();
    // useLayoutEffect(() => {
    //   // every route change
    //   routeScrollToTop();
    // }, [location.pathname, isPop]);
    return children
  } 


function App() {

    useEffect(() => {
        getCurrentLocation();
        // load cache from localstorage
        // loadCache();
        // window.addEventListener('beforeunload', saveCache);
        // return () => {
        //     window.removeEventListener('beforeunload', saveCache);
        //     saveCache();
        // };
    }, []);

    return (
        <HelmetProvider>
            <ErrorBoundary>
            <NotificationContainer />
                <Wrapper>
                    <AppRouteWrapper />
                </Wrapper>
            </ErrorBoundary>
        </HelmetProvider>
    );
}

export default App;
