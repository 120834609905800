import { findItemById, findItemBySlug } from "./ArrayHelper";
import { capitalizeWord } from "./CommonHelper";

export const getFormattedPaymentStatus = (t,status) => {
    switch(status) {
        case "1":
          return t('paid');
        default:
          return t('unknown');
      }
};


export const getFormattedOutletStatus = (t,status) => {
  switch(status) {
      case "1":
        return t('public');
      default:
        return t('private');
    }
};

export const getFormattedTransactionId = (id) => {
  //return "#"+id;
  return id;
};

export const getFormattedInvoiceId = (id) => {
  //return "#"+id;
  return id;
};

export const getProductCategoryStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}
export const getCategoryStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const getindustryStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const getIndustryStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}
export const getVendorRatingStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const getProductCategoryStatusById = (t,id) => {
  const productCategoryStatusByIds = getProductCategoryStatus(t);
  return findItemById(productCategoryStatusByIds,id);
}
export const getVendorRatingStatusById = (t,id) => {
  const vendorRatingStatusByIds = getVendorRatingStatus(t);
  return findItemById(vendorRatingStatusByIds,id);
}
export const getCategoryStatusById = (t,id) => {
  const CategoryStatusByIds = getCategoryStatus(t);
  return findItemById(CategoryStatusByIds,id);
}
export const getTaxTypeStatusById = (t,id) => {
  const taxTypeStatusByIds = gettaxtypeStatus(t);
  return findItemById(taxTypeStatusByIds,id);
}
export const getTaxStatusById = (t,id) => {
  const taxStatusByIds = getStatus(t);
  return findItemById(taxStatusByIds,id);
}
export const getTaxMethodStatusById = (t,id) => {
  const taxMethodStatusByIds = gettaxMethodStatus(t);
  return findItemById(taxMethodStatusByIds,id);
}
export const getMemberShipPlanStatusById = (t,id) => {
  const productCategoryStatusByIds = getMemberShipPlanStatus(t);
  return findItemById(productCategoryStatusByIds,id);
}

export const getRewardSettingStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const getRewardSettingStatusById = (t,id) => {
  const rewardSettingStatusByIds = getRewardSettingStatus(t);
  return findItemById(rewardSettingStatusByIds,id);
}

export const getBillingType = (t) => {
  return [
    { id: 'percentage', name: t("percentage_rp") },
    { id: 'fixed', name: t("fixed_rp") },
    { id: 'range', name: t("fixed_percentage_amount") }
  ];
}

export const getBillingTypeById = (t,id) => {
  const billingTypeByIds = getBillingType(t);
  return findItemById(billingTypeByIds,id);
}

export const getVendorRating = (t)=> {
  return [
    { id: 1, name: "1 Star" },
    { id: 2, name: "2 Star" },
    { id: 3, name: "3 Star" },
    { id: 4, name: "4 Star" },
    { id: 5, name: "5 Star" }
  ];
}

export const getRoleStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}
export const getProductStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const getProductStatusById = (t,id) => {
  const productStatusByIds = getProductStatus(t);
  return findItemById(productStatusByIds,id);
}

export const getOfferStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const getOfferStatusById = (t,id) => {
  const productStatusByIds = getOfferStatus(t);
  return findItemById(productStatusByIds,id);
}

export const getInvoiceType = (t) => {
  return [
    { id: 1, name: t("subscription_purchase") }
  ];
}

export const getInvoiceById = (t,id) => {
  const invoiceTypeByIds = getInvoiceType(t);
  return findItemById(invoiceTypeByIds,id);
}

export const getTransactionType = (t) => {
  return [
    { id: 1, name: t("subscription_purchase") }
  ];
}

export const getTransactionTypeById = (t,id) => {
   const transactionTypeByIds = getTransactionType(t);
   return findItemById(transactionTypeByIds,id);
}

export const getOutletStatus = (t) => {
  return [
    { id: 0, name: t("private") },
    { id: 1, name: t("public") }
  ];
}

export const getOutletStatusById = (t,id) => {
   const outletStatusByIds = getOutletStatus(t);
   return findItemById(outletStatusByIds,id);
}


export const getTransactionStatus = (t) => {
  return [
    { id: 0, name: t("pending") },
    { id: 1, name: t("success") },
    { id: 2, name: t("fail") },
  ];
}

export const getTransactionStatusById = (t,id) => {
  const transactionStatusByIds = getTransactionStatus(t);
  return findItemById(transactionStatusByIds,id);
}



export const getFormattedAddress = (address,city,state,pincode) => {
  return address+', '+city+', '+state+' - '+pincode;
};

export const getFormattedMobile = (mobileCode,mobile) => {
  return "+"+mobileCode+" "+mobile
};

export const getFormattedPointName = (amount,coinName) => {
  return amount+' '+coinName;
};


export const getRewardTypes = (t) => {
  return [
    { id: 1, name: t("refer_reward"), slug: "refer_reward" },
    { id: 2, name: t("levelup_reward"), slug: "levelup_reward" },
    { id: 3, name: t("follow_reward"), slug: "follow_reward" },
    { id: 4, name: t("rating_reward"), slug: "rating_reward" },
    { id: 5, name: t("general_reward"), slug: "general_reward" },
    { id: 6, name: t("product_reward"), slug: "product_reward" },
    { id: 7, name: t("redeem_reward"), slug: "redeem_reward" },
  ];
}

export const getRewardTypesById = (t,id) => {
   const rerewardTypesByIds = getRewardTypes(t);
   return findItemById(rerewardTypesByIds,id);
}

export const getRewardTypesBySlug = (t,slug) => {
  const rerewardTypesByIds = getRewardTypes(t);
  return findItemBySlug(rerewardTypesByIds,slug);
}

export const getRPTransactionStatus = (t) => {
  return [
    { id: 'active', name: t("active") },
    { id: 'expired', name: t("expired") }
  ];
}

export const getRPTransactionStatusById = (t,id) => {
   const rpTransactionStatusByIds = getRPTransactionStatus(t);
   return findItemById(rpTransactionStatusByIds,id);
}


export const deleteConfirmationModalDetails = (t,name) => {
  return {
    title: t('are_you_sure'),
    message: t('do_you_want_to_delete_this_item',{name: t(name)}),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'danger',
    closeButtonText: t('cancel'),
    primaryButtonText: t('yes_delete_it'),
  }
};
export const rejectvarificationConfirmationModalDetails = (t,name) => {
  return {
    title: t('are_you_sure'),
    message: t('to_reject_this_item',{name: t(name)}),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'danger',
    closeButtonText: t('cancel'),
    primaryButtonText: t('yes_reject'),
  }
};

export const approveConfirmationModalDetails = (t,name) => {
  return {
    title: t('are_you_sure'),
    message: t('want_to_approve_this_item',{name: t(name)}),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'info',
    closeButtonText: t('cancel'),
    primaryButtonText: t('yes_approve'),
  }
};

export const startDelegateConfirmationModalDetails = (t) => {
  return {
    title: t('are_you_sure'),
    message: t('start_delegate_confirmation_message'),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'info',
    closeButtonText: t('cancel'),
    primaryButtonText: t('switch_profile'),
  }
};
export const stopDelegateConfirmationModalDetails = (t) => {
  return {
    title: t('are_you_sure'),
    message: t('stop_delegate_confirmation_message'),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'info',
    closeButtonText: t('cancel'),
    primaryButtonText: t('switch_profile'),
  }
};

export const accountActiveConfirmationModalDetails = (t,name) => {
  return {
    title: t('are_you_sure'),
    message: t('do_you_want_to_activate_account',{name: t(name)}),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'info',
    closeButtonText: t('cancel'),
    primaryButtonText: t('proceed_to_apply'),
  }
};
export const offerStatusColor = (status) => {
  var color = "";
  switch (status) {
      case "Upcoming":
          color = "text-info";
          break;
      case "Expired":
          color = "text-danger";
          break;
      case "Running":
          color = "text-success";
          break;
      default:
          color = "text-muted";
  }

  return <p className={`${color}`}>{status}</p>;
}
export const countryStatusColor = (status) => {
  var color = "";
  switch (status) {
      case "Active":
          color = "text-success";
          break;
      case "Deactive":
          color = "text-danger";
          break;
      default:
          color = "text-muted";
  }

  return <span className={`${color}`}>{status}</span>;
}
export const profileActivationHistoryStatus = (t, status) => {
  var label = "";
  switch (status) {
      case "1":
          label = <span className="text-app">{t('applied_for_activation')}</span>;
          break;
      case "2":
        label = <span className="text-success">{t('approved')}</span>;
          break;
      case "3":
        label = <span className="text-danger">{t('rejected')}</span>;
          break;
      default:
        label = <span className="text-muted">{t('unknown')}</span>;
  }

  return label;
}

export const developerKeyConfirmationModalDetails = (t,name) => {
  return {
    title: t('are_you_sure'),
    message: t('do_you_want_to_delete_this_item',{name: t(name)}),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'danger',
    closeButtonText: t('cancel'),
    primaryButtonText: t('yes_delete_it'),
  }
};
export const developerKeyGenerateConfirmationModalDetails = (t,name) => {
  return {
    title: t('are_you_sure'),
    message: t('do_you_want_to_generate_the_key'),
    closeButtonColor: 'secondary',
    primaryButtonColor: 'btn blue_rev_btn',
    closeButtonText: t('no'),
    primaryButtonText: t('yes'),
  }
};
export const getRedeemStatus = (t) => {
  return [
    { id: 1, name: t("enabled") },
    { id: 0, name: t("disabled") }
  ];
}

export const getRedeemStatusById = (t,id) => {
  const redeemStatusByIds = getRedeemStatus(t);
  return findItemById(redeemStatusByIds,id);
}
export const getRoleOptionObj = (element, setDisabled=false) => {
  var obj = {
    id: element.id,
    name: capitalizeWord(element.name)
  }
  if(setDisabled){
    obj = {...obj, ...{isDisabled: element.status ? false : true}}
  }
  return obj
}
export const weekDays = (t) => {
  return {
    "sun": "Sunday",
    "mon": "Monday",
    "tue": "Tuesday",
    "wed": "Wednesday",
    "thurs": "Thursday", 
    "fri": "Friday",
    "sat": "Saturday"
  };
}

export const getlanguageStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}

export const gettaxclassStatus = (t) => {
  return [
    { id: 1, name: t("Public") },
    { id: 0, name: t("Private") }
  ];
}
export const gettaxtypeStatus = (t) => {
  return [
    { id: 1, name: t("Fixed") },
    { id: 2, name: t("Percentage") }
  ];
}
export const gettaxMethodStatus = (t) => {
  return [
    { id: 1, name: t("Inclusive") },
    { id: 2, name: t("Exclusive") }
  ];
}


export const getStatus = (t) => {
  return [
    { id: 1, name: t("active") },
    { id: 0, name: t("deactive") }
  ];
}
export const getStatusById = (t,id) => {
  const statusByIds = getStatus(t);
  return findItemById(statusByIds,id);
}
export const getRewardTypeStatus = (t) => {
  return [
    { id: 1, name: t("Public") },
    { id: 0, name: t("Private") }
  ];
}
export const getRewardTypeStatusById = (t,id) => {
  const RewardTypeStatusByIds = getRewardTypeStatus(t);
  return findItemById(RewardTypeStatusByIds,id);
}
export const getMemberShipPlanStatus = (t) => {
  return [
    { id: 1, name: t("Private") },
    { id: 0, name: t("Public") }
  ];
}
export const getTrailPlanTypeStatus = (t) => {
  return [
    { id: 1, name: t("Yes") },
    { id: 0, name: t("No") }
  ];
}
export const getTrailPlanTypeStatusById = (t,id) => {
  const trialPlanTypeStatusByIds = getTrailPlanTypeStatus(t);
  return findItemById(trialPlanTypeStatusByIds,id);
}
export const getRestricted = (t) => {
  return [
    { id: 1, name: t("Yes") },
    { id: 0, name: t("No") }
  ];
}

export const getContentStatusById = (t,id) => {
  const ContentTypeStatusByIds = getStatus(t);
  return findItemById(ContentTypeStatusByIds,id);
}
export const getRestrictedStatusById = (t,id) => {
  const RestrictedTypeStatusByIds = getRestricted(t);
  return findItemById(RestrictedTypeStatusByIds,id);
}


export const getSocialMediaIcon = (type) => {
  const typeList = {
    "facebook": "bi-facebook",
    "linkedin": "bi-linkedin",
    "instagram": "bi-instagram",
    "twitter": "bi-twitter",
  }
  return typeList[type]|| "";
}
export const getTicketPriorityList = (t) => {
  return [
    { id: 'Low', name: t("low") },
    { id: 'Medium', name: t("medium") },
    { id: 'High', name: t("high") }
  ];
}
export const getTicketStatusList = (t) => {
  return [
    { id: 'Open', name: t("open") },
    { id: 'Close', name: t("Close") }
  ];
}
export const getTicketStatusById = (t,id) => {
  const RestrictedTypeStatusByIds = getTicketStatusList(t);
  return findItemById(RestrictedTypeStatusByIds,id);
}