import Header from "components/_common/Header";
import Sidebar from "components/_common/Sidebar";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";

export default function AppLayout(props) {

    const [isCollapseMenu,setCollapseMenu] = useState(false);

    const toggleNavigation = () => {
        setCollapseMenu(!isCollapseMenu);
    };

    return (
    <ProtectedRoute>
        <div className="main_ctnr">
            <Sidebar isCollapseMenu={isCollapseMenu} toggleNavigation={toggleNavigation} />
            <div className={`main ${isCollapseMenu ? 'act_nav_close' : ''}`}>
	  	        <div className="card_part">
		  		    <div className="container-fluid">
                        <Header />
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    </ProtectedRoute>
    );
}